import React, { useEffect, useState, useContext } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import AppRoutes from './route/AppRoutes';
import { serverFormPost } from './middleware/http';
import { apiConst } from './services/constant/constant';
import { ImageCropContextProvider } from './services/utils/imageCrop';
import Layout from './theme/default/Layout';
import { DoctorProvider } from './services/Context/DoctorContext';
import { getAuthProfile, isArray } from './services/utils/validation';
import { EncounterProvider } from './services/Context/JoinCall';
import { LanguageResProvider } from './services/Context/LanguageContext';
import { useLayoutEffect } from 'react';
import { getSearchParams } from './services/utils/validation';
import ProgressContext from "./services/utils/progress";
import { useAccountSettings } from './services/Context/AccSettingContext';

export default function App() {
    const [applicationRoutes, setApplicationRoutes] = useState([]);
    const [islogin, setIslogin] = useState((/security\/(forgotpassword|login|resetpassword)/gm.test(window.location.pathname.toLowerCase())));
    const [isDocViewer, setIsDocViewer] = useState(false);
    const location = useLocation();
    const { progParams } = useContext(ProgressContext);
    const { account, errMsg } = useAccountSettings();
    const clientId = getSearchParams("client_id");
    const clientSecret = getSearchParams("client_secret");

    const initAccount = (data) => {
        try {
            const app_routes = [...AppRoutes];
            const _defaultPage = data?.portalSettings?.find(obj => obj.code === "DFP");
            if (_defaultPage?.value?.toUpperCase() == "SGI") {
                app_routes[0].element = <Navigate to={getAuthProfile.getProfileAuth() ? "/dashboard" : "/security/login"} replace />
            }
            setApplicationRoutes(app_routes);
        } catch (e) {
            console.log(e);
        }
    }

    const checkSSOLogin = async () => {
        progParams(true);
        try {
            let auth_res = getAuthProfile.getProfileAuth();
            if (auth_res && auth_res != "false") {
                if (clientId != null && typeof clientId != "undefined") {
                    // progParams(true);
                    let frmData = new FormData();
                    frmData.append("client_id", clientId);
                    frmData.append("client_secret", clientSecret);
                    frmData.append("grant_type", "client_credentials");
                    //axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    try {
                        await serverFormPost(`${apiConst.authorizeGet}`, frmData, false)
                            .then(res => {
                                //console.log(`${redirect_uri}&code=${res.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${to_redirect_uri}`);
                                //return window.location.href = `${redirect_uri}&code=${res.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${to_redirect_uri}`;//&iss=https%3A%2F%2Flocalhost%3A44324%2F

                            });

                    } catch (err) { }
                }
            }

        } catch (err) {
            progParams(false);
        } finally {
            progParams(false);

        }
    }

    useLayoutEffect(() => {
        if (isArray(errMsg)) {
            localStorage.setItem("errorMsg", JSON.stringify(errMsg));
        }
    }, [errMsg])

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                await checkSSOLogin();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    useLayoutEffect(() => {
        if (account) {
            const fetchData = async () => {
                try {
                    await initAccount(account);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
    }, [account])

    useEffect(() => {
        const _isExternal = getSearchParams("isexternal");
        window.scrollTo(0, 0);


        if (location.pathname.includes("security")) {
            setIslogin(true);
        } else {
            setIslogin(false);
        }

        if (location.pathname.includes("docviewer") || location.pathname.includes("pagenotfound") || _isExternal) {
            setIsDocViewer(true);
        } else {
            setIsDocViewer
                (false);
        }

    }, [location]);

    return (
        <LanguageResProvider>
            <ImageCropContextProvider>
                <DoctorProvider>
                    <EncounterProvider>
                        <Layout islogin={islogin} isViewer={isDocViewer}>
                            <Routes>
                                {isArray(applicationRoutes) && applicationRoutes?.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </Layout>
                    </EncounterProvider>
                </DoctorProvider>
            </ImageCropContextProvider>
        </LanguageResProvider>

    )
}


