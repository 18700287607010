import React, { useEffect} from 'react';
import VitalList from './vitalList';

export default function VitalAdd() {

    useEffect(() => {
        document.title = "Vitals";
    }, []);

    const tabData = {
        code:"HP1"
    }

    return (
        <>
            <VitalList tabData={tabData} />
        </>
    )
}



