import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import ActionStatus from '../../../services/utils/actionStatus';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useProfile } from '../../../services/utils/profileContext';
import ProgressContext from '../../../services/utils/progress';
import { getAuthProfile, getSearchParams, isEnabled, updateSearchParams } from '../../../services/utils/validation';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import LazyImageComponent from '../../../services/utils/LazyImageComponent';

export default function ProviderList() {
    const [providerList, setProviderList] = useState([]);
    const [specialityList, setSpecialityList] = useState([]);
    const [search, setSearch] = useState('');
    const [specialitySearch, setSpecialitySearch] = useState('');
    const [specialityId, setSpecialityId] = useState('');
    const { progParams } = useContext(ProgressContext);

    const navigate = useNavigate();
    const { AccountCurrency, showAccPricing } = useAccountSettings();
    const { checkUserProfile, getFamilyList } = useProfile();
    const { getLanguageContent } = useLanguageRes();
    const { accountSettings } = useAccountSettings();


    const getList = async () => {
        const residing_Id = getSearchParams("rsid");
        if (specialityId) {
            progParams(true);
            setProviderList("loading");

            const _typeId = getSearchParams("ftypeid");

            const pricingAtr = [
                {
                    attributeid: "101",
                    recordtypeid: "33",
                    recordid: _typeId ? _typeId : "1"
                },
                {
                    attributeid: "102",
                    recordtypeid: "32",
                    recordid: specialityId
                }
            ]

            try {
                const res = await serverGet(`${apiConst.getproviderlist}?spid=${specialityId}&kwd=${search == '' ? '-' : search}${residing_Id ? `&rsid=${residing_Id}` : ""}&atr=${JSON.stringify(pricingAtr)}`);
                progParams(false);
                if (res?.status == 1) {
                    setProviderList(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleSearchChange = e => {
        setSearch(e.target.value);
    };


    const providerprofile = (memberid, minPrice) => {

        const specialityId = getSearchParams("spid") ? `spid=${getSearchParams("spid")}` : '';
        const residingId = getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : '';
        const isDefaultSpecialty = getSearchParams("isds") ? `isds=${getSearchParams("isds")}` : '';
        const _brtype = getSearchParams("brtype");

        navigate(`/profiledetail?memberid=${memberid}${specialityId ? `&${specialityId}` : ""}${residingId ? `&${residingId}` : ""}${minPrice ? `&minP=${minPrice}` : ""}${isDefaultSpecialty ? `&isds=${isDefaultSpecialty}` : ""}&sinp=t&brtype=${_brtype}`);
    }

    const booknowBtn = async (memberId, minPrice) => {
        try {
            const specialityId = getSearchParams("spid") ? `spid=${getSearchParams("spid")}` : '';
            //const residingId = getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : '';
            const isDefaultSpecialty = getSearchParams("isds") ? `isds=${getSearchParams("isds")}` : '';
            const residingId = getSearchParams("rsid") ? `rsid=${getSearchParams("rsid")}` : '';
            const _brtype = getSearchParams("brtype");

            const bookingUrl = `/booking?memberid=${memberId}${specialityId ? `&${specialityId}` : ""}${residingId ? `&${residingId}` : ""}${minPrice ? `&minP=${minPrice}` : ""}${isDefaultSpecialty ? `&${isDefaultSpecialty}` : ""}&brtype=${_brtype}`
            const addProfileUrl = `/addprofile?a=y&memberid=${memberId}${specialityId ? `&${specialityId}` : ""}${residingId ? `&${residingId}` : ""}${minPrice ? `&minP=${minPrice}` : ""}${isDefaultSpecialty ? `&${isDefaultSpecialty}` : ""}&brtype=${_brtype}`
            const _familyMemberUrl = `/selectfamilymember?memberid=${memberId}${specialityId ? `&${specialityId}` : ""}${residingId ? `&${residingId}` : ""}${minPrice ? `&minP=${minPrice}` : ""}${isDefaultSpecialty ? `&${isDefaultSpecialty}` : ""}&brtype=${_brtype}`
            const _identificationUrl = `/idverification/upload?memberid=${memberId}${specialityId ? `&${specialityId}` : ""}${residingId ? `&${residingId}` : ""}${minPrice ? `&minP=${minPrice}` : ""}${isDefaultSpecialty ? `&${isDefaultSpecialty}` : ""}&brtype=${_brtype}`
            //also check profile is update profileContext?.resContact == null idVerification


            const redirectUrl = {
                path: bookingUrl,
                bookingUrl: bookingUrl,
                familyMemberUrl: _familyMemberUrl,
                profileUrl: addProfileUrl,
            }
            if (!getAuthProfile.getProfileAuth()) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));

                return navigate(`/security/login?c=y${residingId ? `&${residingId}` : ""}`)
            }

            let _isProfile;
            let _isFamilyMember;

            if (getAuthProfile.getProfileAuth()) {
                _isProfile = await checkUserProfile();
                _isFamilyMember = await getFamilyList();
            }
            updateSearchParams("pid", memberId);
            updateSearchParams("minP", minPrice);




            if (!_isProfile?.pofileCompleted) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                return navigate(addProfileUrl);
            } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                return navigate(_identificationUrl);
            } else if (_isFamilyMember) {
                return navigate(_familyMemberUrl);
            } else {
                return navigate(bookingUrl);
            }

            //if (_isFamilyMember && _isProfile?.pofileCompleted) {
            //    return navigate(_familyMemberUrl);
            //} else if (getAuthProfile.getProfileAuth() && _isProfile?.pofileCompleted) {
            //    return navigate(bookingUrl);
            //} else if (getAuthProfile.getProfileAuth() && !_isProfile?.pofileCompleted) {
            //    const redirectUrl = {
            //        path: _isFamilyMember ? _familyMemberUrl : bookingUrl
            //    }
            //    sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            //    return navigate(addProfileUrl);
            //} else if (getAuthProfile.getProfileAuth() && isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
            //    const redirectUrl = {
            //        path: _isFamilyMember ? _familyMemberUrl : bookingUrl
            //    }
            //    sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            //    return navigate(_idVerificationUrl);
            //}




            //sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
            //return navigate(`/security/login?c=y${residingId ? `&${residingId}` : ""}`);
        } catch (e) {
            console.log(e)
        }
    }




    const getSpecialityList = () => {
        const residing_Id = getSearchParams("rsid");
        const speciality_Id = getSearchParams("spid");

        progParams(true);
        serverGet(`${apiConst.getproviderspeciality}${residing_Id ? `?sid=${residing_Id}` : ""}`).then((res) => {
            progParams(false);
            try {
                if (res?.data) {
                    if (Array.isArray(res?.data) && res?.data?.length > 0) {
                        const resData = [...res?.data];
                        const indexToRemove = resData?.findIndex(obj => obj.code === "OTHER");
                        if (indexToRemove !== -1) {
                            const dataToRemove = resData.splice(indexToRemove, 1)[0];
                            resData.push(dataToRemove);
                            setSpecialityList([...resData]);
                        } else {
                            setSpecialityList(resData);
                        }
                        if (!speciality_Id) {
                            const sp_id = resData[0]?.id
                            updateSearchParams("spid", sp_id);
                            setSpecialityId(sp_id);
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }).catch((e) => {
            progParams(false);
            console.log(e);
        })
    }


    useEffect(() => {
        document.title = "Search provider";
        const speciality_Id = getSearchParams("spid");
        const searchQuery = getSearchParams("kwd");
        setSpecialityId(speciality_Id || '');
        setSearch(searchQuery || '');
        getSpecialityList();

    }, []);


    useEffect(() => {
        if (specialityId) {
            updateSearchParams("spid", specialityId);
            getList();
        }
    }, [specialityId]);

    useEffect(() => {
        updateSearchParams("kwd", search);
        getList();
    }, [search]);



    return (
        <div className="px-2 px-md-0">
            <ScheduleHeader title={getLanguageContent("slctprovider")} />
            <small id="searchHelp" className="form-text">{getLanguageContent("srchbyprovider")}</small>
            <div className="d-flex mb-3">
                <div className="flex-grow-1">
                    <div className="input-group">
                        <select className="form-select w-25" onChange={(e) => setSpecialityId(e.target.value)}>
                            {specialityList?.length > 0 && specialityList?.filter((list) =>
                                list?.name?.toLowerCase().includes(specialitySearch.toLowerCase())).map((list, index) => (
                                    <option value={list?.id} selected={specialityId == list?.id} key={index}>{list?.name}</option>
                                ))}
                        </select>

                        <input autoFocus={true} value={search} onChange={handleSearchChange} type="text" className="form-control w-75" id="doctor_search" placeholder={getLanguageContent("srch")} />
                    </div>
                </div>
            </div>
            
            {(providerList?.length > 0 && Array.isArray(providerList)) && providerList?.filter((list) =>
                list.name.toLowerCase().includes(search.toLowerCase()) ||
                list.speciality.toLowerCase().includes(search.toLowerCase())).map((user, index) => (
                    <div key={index} className="card border-0 mb-3">
                        <div className="card-body">
                            <div className="d-flex flex-md-row flex-column">
                                <div className="text-center flex-shrink-0">
                                    <LazyImageComponent
                                        className="img-thumbnail provider-list-img"
                                        src={!user?.photoPath ? require('../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(user?.photoPath)}
                                        alt={user?.name}
                                    />
                                </div>
                                <div className="flex-grow-1 mx-3 text-center text-md-start">
                                    <h5>
                                        <a onClick={() => providerprofile(user?.memberId, user?.minimumPrice)} className="text-primary">{user?.name}</a>
                                    </h5>
                                    <div className="mb-2">
                                        {user?.speciality?.split(",").map((speciality, i, arr) => {
                                            return <small key={`speciality${i}`}>{speciality}{i != (arr.length - 1) ? ', ' : ''}</small>
                                        })}
                                    </div>
                                    <div>
                                        <p>
                                            {user?.inPerson && <span className="badge rounded-pill border text-primary border-primary me-2">{getLanguageContent("inprsn")}</span>}
                                            {user?.online && <span className="badge rounded-pill border text-primary border-primary me-2">{getLanguageContent("onlne")}</span>}
                                            {user?.phone && <span className="badge rounded-pill border text-primary border-primary me-2">{getLanguageContent("phne")}</span>}
                                            {user?.phoneOnline && <span className="badge rounded-pill border text-primary border-primary">{`${getLanguageContent("phne")}/${getLanguageContent("onlne")}`}</span>}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-grid my-auto mx-auto flex-shrink-0">
                                    <div className="text-center">
                                        {AccountCurrency?.value && user?.minimumPrice && showAccPricing && isEnabled(accountSettings, "PFS", 1) ? (
                                            <div className="flex-fill">
                                                <span className="text-secondary">{getLanguageContent("feefrm")}</span>
                                                <br /><span className="fs-5 text-secondary">{AccountCurrency?.value}</span><span className="fs-5 fw-semibold">{user?.minimumPrice?.toFixed(2)}</span>
                                            </div>
                                        ) : ""}


                                        <button onClick={() => {
                                            booknowBtn(user?.memberId, isEnabled(accountSettings, "PFS", 1) ? user?.minimumPrice : 0)
                                        }} className="btn btn-primary">{getLanguageContent("boknw")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {((providerList?.length === 0 || providerList == null || !providerList) && (providerList !== "loading")) && <ActionStatus description={getLanguageContent("fmnrfo")} button={""} imageid="#svg_failed" onsyncchange={""} type="search" showSvg={true} />}
        </div>
    );
}


//check overall price settings