import React from "react"
import { getMemberId, getSearchParams, isArray } from "../../../../../services/utils/validation"
import CustomForm from "../customForm";
import { useState } from "react";
import { apiConst } from "../../../../../services/constant/constant";
import { serverGet } from "../../../../../middleware/http";
import { useEffect } from "react";

export default function ExternalForm() {
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [selectedFormInputId, setSelectedFormInputId] = useState(null);
    const [formsList, setFormsList] = useState([]);
    const [isWriteForms, setIsWriteForms] = useState(false); // CHOOSED MULTIPLE ENTRY IS WRITE
    const [isShowAssesment, setIsShowAssesment] = useState(false); // SHOW ASSESMENT FIELD BASED ON LIST

    const getAreaName = (id) => {
        const _id = id.toString();

        switch (_id) {
            case "6":
                return "direct-forms";
            case "2":
                return "encounter-forms";
            case "3":
                return "intake-flow";
            case "4":
                return "patient-forms";
            default:
                return "";
        }
    };



    const getForms = async () => {
        try {
            let encounter_id = getSearchParams("eid");
            let fmid = getSearchParams("fmid"); // family Member Id
            let speciality_id = getSearchParams("spid"); // family Member Id
            const areaId = getSearchParams("areaid");
            const _pmId = getSearchParams("pmid"); // PATIENT MEMBER ID
            const _memberId = await getMemberId();

            const url = `${apiConst.formsList}${fmid ? fmid : _pmId ? _pmId : _memberId}?areaid=${areaId}${encounter_id ? `&eid=${encounter_id}` : ""}${speciality_id ? `&sid=${speciality_id}` : ""}`

            const res = await serverGet(url);
            if (res?.data != null && res.status == 1) {
                setFormsList(res?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {

        }

    }

    useEffect(() => {
        getForms();
    }, [])



    useEffect(() => {
        let _formId = selectedFormId || getSearchParams("frmid")?.toLowerCase() || null

        if (_formId && Array.isArray(formsList) && formsList?.length > 0) {
            const temp = formsList?.find((f) => f.formId == _formId)
            setIsWriteForms(temp?.write);

            setIsShowAssesment(temp?.isShowNextAssessmentDate)
            //if (level == "encounter-forms") {
            //    setIsShowAssesment(temp?.isShowNextAssessmentDate && tabData?.isShowNextAssessmentDate)
            //} else {
            //    setIsShowAssesment(temp?.isShowNextAssessmentDate)
            //}
        }


        // console.log(formsList,"formsList")

    }, [formsList, selectedFormId])

    return (
        <>
            {isArray(formsList) && (
                <CustomForm
                    level={getAreaName(getSearchParams("areaid"))}
                    formsList={formsList}
                    isExternalForms={true}
                    selectedFormId={selectedFormId}
                    setSelectedFormId={setSelectedFormId}
                    setSelectedFormInputId={setSelectedFormInputId}
                    selectedFormInputId={selectedFormInputId}
                    //tabData={tabData}
                    //showMultipleForms={showMultipleForms}
                    //getTemplate={getTemplate}
                    showAssessment={isShowAssesment}
                    //handlePublicForms={handlePublicForms}
                />
            )}
        </>
    )
}