import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { clearAuth, serverGet, serverPut } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import { formValidation, getAuthProfile, getMsg, getSearchParams, removeSearchParam, updateSearchParams } from '../../../services/utils/validation';
import DoctorSummary from '../consultation/summary/DoctorSummary';
import PharmacyList from './PharmacyList';
import ProgressContext from '../../../services/utils/progress';
import { useContext } from 'react';
import NotifyContext from '../../../services/utils/notify';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import { useLanguageRes } from '../../../services/Context/LanguageContext';

export default function Pharmacy() {

    const { register, handleSubmit, control, setValue, watch,reset, formState: { errors } } = useForm();
    const [selectedApi, setSelectedApi] = useState();
    const [preferences, setPreferences] = useState([]);
    const [autoCompleteloading, setAutoCompleteloading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [location, setLocation] = useState([]);
    const [searchList, setSearchList] = useState([]);
    //const [postalCode, setPostalCode] = useState("");
    const [isNoresults, setIsNoResults] = useState(false);

    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const member_id = localStorage.getItem("memberId");

    const urlSearchParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const { getLanguageContent } = useLanguageRes();
    const locationSearch = useRef();

    const getaddresslist = (data) => {
        const enc_id = getSearchParams("eid");
        setAutoCompleteloading(true);
        serverGet(`${apiConst.pharamacyautocomplete}/${enc_id}?kw=${data}`).then((res) => {
            setAutoCompleteloading(false);
            try {
                if (res.data && res.data !== null && res?.status == 1) {
                    setAddressList(res.data);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const debouncedSave = useCallback(
        debounce((newValue) => getaddresslist(newValue), 1000),
        []
    );

    const updateValue = (newValue) => {
        debouncedSave(newValue);
    };


    const selectdata = (item, type) => {
        //const newUrl = new URL(window.location);

        //console.log(item);

        if (selectedApi?.id == item?.id && type == selectedApi?.type) {

            //ecnSession(" ", "phaid");
            //ecnSession(" ", "pharmacyname");

            removeSearchParam("phaid");
            removeSearchParam("p");

            return setSelectedApi("");
        }

        //ecnSession(item?.id, "phaid");
        //ecnSession(type, "phalist");
        //ecnSession(item?.name, "pharmacyname");

        updateSearchParams("phaid", item?.id);
        updateSearchParams("phalist", type);
        //updateSearchParams("pharmacyname", item?.name);


        setSelectedApi({
            id: item?.id,
            type: type,
            name: item?.name
        });


        //newUrl.searchParams.set('p', item?.name);
        updateSearchParams("p", item?.name);

        //window.history.replaceState(null, '', newUrl);

        // console.log(item?.name);
    }

    const onSubmit = () => {
        //const sessionStore = ecnSession();
        const app_date = urlSearchParams.get('ad');
        const time_slot = urlSearchParams.get('ts');
        const duration_name = urlSearchParams.get('dn');
        const enc_mode = urlSearchParams.get('m');
        const enc_reason = urlSearchParams.get('r');
        const enc_reason_note = urlSearchParams.get('rnote');
        const enc_id = urlSearchParams.get('eid');
        const enc_pharmacy = urlSearchParams.get('p');
        const provider_memberId = urlSearchParams.get('memberid');
        const pharmacy_id = getSearchParams("phaid") || "";
        const encounterModeId = getSearchParams("emid");
        const durationId = getSearchParams("did");
        const minimum_price = getSearchParams("minP");
        const schedule_id = getSearchParams("schid");
        const pharmacy_type = getSearchParams("phalist") || "";
        const pharmacy_state_id = getSearchParams("pharmacyStateId") || "";
        const postal_code = getSearchParams("postalcode") || "";
        const pharmacy_name_search = getSearchParams("pharmacysearch") || "";
        const _reascode = getSearchParams("reascode");
        const _typeid = getSearchParams("ftypeid");
        const fmid = getSearchParams("fmid");
        const residingState = getSearchParams("rsid");
        const _snum = getSearchParams("snum");
        const _brtype = getSearchParams("brtype");

        if (selectedApi && selectedApi?.id?.trim() && pharmacy_id?.trim()) {

            const formadata = {
                memberId: fmid ? fmid : member_id,
                preferredPharmacyId: pharmacy_id,
                //statusId: appConst.encStatusId.scheduled,

            }
            progParams(true);
            serverPut(`${apiConst.encounterpharmacy}${enc_id}`, formadata).then((res) => {
                progParams(false); // check overall payment setting
                try {
                    if (res?.status == 1) {
                        navigate(`/appointment/summary?memberid=${provider_memberId}&ad=${app_date}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${enc_reason}&rnote=${enc_reason_note}&eid=${enc_id}&p=${encodeURIComponent(enc_pharmacy)}&emid=${encounterModeId}&did=${durationId}&minP=${minimum_price}&schid=${schedule_id}&isp=${"1"}&phaid=${pharmacy_id}&phalist=${pharmacy_type}&pharmacysearch=${pharmacy_name_search}&pharmacyStateId=${pharmacy_state_id}&postalcode=${postal_code}&spid=${getSearchParams("spid")}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${residingState ? `&rsid=${residingState}` : ""}${_snum ? `&snum=${_snum}` : ""}&brtype=${_brtype}`);
                    }
                    else {
                        var error = JSON.parse(res.data.errorMessage);
                        notifyToast(error.messageCode[0]);
                    }
                } catch (e) {
                    console.log(e);
                }
            })

        } else {
            notifyToast(319);
        }

    }


    const getList = async (data) => {
        try {
            const enc_id = getSearchParams("eid");

            if (data?.pharmacyStateId?.trim() || data.postalcode.trim() || data?.pharmacyname?.trim()) {

                if (data?.pharmacyStateId) {
                    //ecnSession(data?.pharmacyStateId, "pharmacyStateId");
                    updateSearchParams("pharmacyStateId", data?.pharmacyStateId);

                }
                if (data?.postalcode) {
                    // ecnSession(data?.postalcode, "postalcode");
                    updateSearchParams("postalcode", data?.postalcode);

                    // setPostalCode(data?.postalcode);
                }
                if (data?.pharmacyname) {
                    //ecnSession(data?.pharmacyname, "pharmacysearch");
                    updateSearchParams("pharmacysearch", data?.pharmacyname);

                }

                progParams(true);
                setIsNoResults(false);
                const res = await serverGet(`${apiConst.pharamacysearch}${enc_id}/${50}/${0}?kw=${data?.pharmacyname?.trim() ? data?.pharmacyname : "-"}&zip=${data.postalcode ? data.postalcode : "-"}&ct=${data?.pharmacyStateId ? data?.pharmacyStateId : "-"}`);
                progParams(false);
                if (res?.status == 1) {
                    if (res?.data == null)
                        setIsNoResults(true);
                    setSearchList(res?.data);
                } else {
                    const error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };



    const getPreferenceList = async () => {
        try {
            let member_id = localStorage.getItem("memberId");

            if (!member_id) {
                const patient_res = await serverGet(apiConst.patientinfo);
                if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId)
                    member_id = patient_res?.data?.memberId;
                localStorage.setItem("memberId", member_id)

            }
            const provider_member_id = getSearchParams("memberid");
            const residingState = getSearchParams("rsid");

            const fmid = getSearchParams("fmid");
            progParams(true);
            const res = await serverGet(`${apiConst.preferencelist}${fmid ? fmid : member_id}?providerid=${provider_member_id}&rid=${residingState}`);
            progParams(false);
            if (res?.status == 1) {
                setPreferences(res?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const resetForm = () => {
        if (getSearchParams("phalist") == "list") {
            removeSearchParam("phaid");
        }

        removeSearchParam("postalcode");
        removeSearchParam("pharmacyStateId");
        removeSearchParam("pharmacysearch");

  
        if (locationSearch.current) {
            locationSearch.current.clear();
        }
        reset({ pharmacyStateId: "", pharmacyname: "", postalcode :""});
        setLocation([]);
        setSearchList([]);

    }
    //console.log(locationSearch.clear())

    //useEffect(() => {
    //    if (location?.length > 0 && location)
    //        ecnSession(location, "phasearch");
    //}, [location]);



    useEffect(() => {
        document.title = "Pharmacy";
        getPreferenceList();
        const selected_Phar = getSearchParams("p");
        const pharmacy_id = getSearchParams("phaid");
        const pharmacy_type = getSearchParams("phalist");
        const pharmacy_state_id = getSearchParams("pharmacyStateId");
        const postal_code = getSearchParams("postalcode");
        const pharmacy_name_search = getSearchParams("pharmacysearch");
        //const sessionStore = ecnSession();
        if (selected_Phar) {
            //setSelectedApi({ name: selected_Phar, id: sessionStore?.phaid, type: sessionStore?.phalist });
            setSelectedApi({ name: selected_Phar, id: pharmacy_id, type: pharmacy_type });
            //getList({
            //    pharmacyStateId: sessionStore?.pharmacyStateId ? sessionStore?.pharmacyStateId : "",
            //    postalcode: sessionStore?.postalcode ? sessionStore?.postalcode : "",
            //    pharmacyname: sessionStore?.pharmacysearch ? sessionStore?.pharmacysearch : "",
            //})

            getList({
                pharmacyStateId: pharmacy_state_id ? pharmacy_state_id : "",
                postalcode: postal_code ? postal_code : "",
                pharmacyname: pharmacy_name_search ? pharmacy_name_search : "",
            })

            //if (sessionStore?.pharmacyStateId?.trim()) {
            //    setLocation([{
            //        city: sessionStore?.pharmacyStateId
            //    }]);
            //    setValue('pharmacyStateId', sessionStore?.pharmacyStateId);

            //}


            if (pharmacy_state_id?.trim()) {
                setLocation([{
                    city: pharmacy_state_id
                }]);
                setValue('pharmacyStateId', pharmacy_state_id);

            }

            //if (sessionStore?.postalcode?.trim()) {
            //    setValue('postalcode', sessionStore?.postalcode);
            //}
            if (postal_code?.trim()) {
                setValue('postalcode', postal_code);
            }

            //if (sessionStore?.pharmacysearch?.trim()) {
            //    setValue('pharmacyname', sessionStore?.pharmacysearch);
            //}

            if (pharmacy_name_search?.trim()) {
                setValue('pharmacyname', pharmacy_name_search);
            }
        }
    }, []);

    const filterBy = () => true;

    return (
        <>
            <ScheduleHeader title={getLanguageContent("prfrdphrmcy")} />
            <div className="d-flex flex-md-row flex-column">
                <DoctorSummary />
                <div className="flex-grow-1">
                    <div className="card border-0">
                        <div className="card-body p-2 p-md-4">
                            {preferences?.length > 0 &&
                                <>
                                    <h6>{getLanguageContent("myprfrnce")}</h6>
                                    <div className="scroll-list-container">
                                        <PharmacyList list={preferences} click={selectdata} selected={selectedApi} type={"preferences"} />
                                    </div>
                                </>
                            }
                            <div>
                                <p>{getLanguageContent("srchslct")}</p>
                                <form onSubmit={handleSubmit(getList)} autoComplete="off">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-6 mb-3">
                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={getLanguageContent("phynme")}  {...register('pharmacyname')} />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <Controller
                                                control={control}
                                                name="pharmacyStateId"
                                                rules={{
                                                    //required: getMsg(301)
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <div className="">
                                                        {/*<label htmlFor="pharmacyStateId" className="form-label">State,city<span className="text-danger star">*</span></label>*/}
                                                        <AsyncTypeahead  {...field}
                                                            name="pharmacyStateId"
                                                            id="pharmacyStateId"
                                                            filterBy={filterBy}
                                                            ref={locationSearch}
                                                            isLoading={autoCompleteloading}
                                                            labelKey="city"
                                                          
                                                            onSearch={(query) => {
                                                                updateValue(query)
                                                                setValue('pharmacyStateId', query);

                                                            }}
                                                            minLength={2}
                                                            selected={location}
                                                            onChange={(selected) => {
                                                                setLocation(selected);
                                                                setValue('pharmacyStateId', selected[0]?.city);
                                                            }}
                                                            options={addressList}
                                                            placeholder={getLanguageContent("cty")}
                                                            className={fieldState.invalid ? "is-invalid" : ""}
                                                            aria-describedby="typeaheadError"
                                                            renderMenuItemChildren={(option) => (
                                                                <div>
                                                                    {option?.city}
                                                                </div>
                                                            )}
                                                        />
                                                        {/*    {errors?.pharmacyStateId?.message && location.length == 0 && <span className="text-danger">{errors?.pharmacyStateId?.message}</span>}*/}
                                                    </div>
                                                )}
                                            />

                                        </div>
                                        <div className="col-lg-3 col-xl-2 mb-3">
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="postal-code" placeholder={getLanguageContent("zpcde")} {...register('postalcode', { required: getMsg(301), pattern: formValidation.number.pattern, })} />
                                            </div>
                                            <div className="form-text text-danger">{errors.postalcode?.message}</div>
                                        </div>
                                        <div className="col-12 mb-3 text-end">
                                            <button type="submit" className="btn btn-primary me-2">{getLanguageContent("srch")}</button>
                                            {(watch('pharmacyStateId') || watch('postalcode') || watch('pharmacyname')) && (
                                                <button type="reset" onClick={resetForm} className="btn btn-secondary">{getLanguageContent("rset")}</button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={searchList?.length > 0 ? "" : ""}>
                                <PharmacyList list={searchList} click={selectdata} selected={selectedApi} type={"list"} isNoresults={isNoresults} />
                            </div>
                            {(isNoresults || searchList?.length > 0) && (
                                <hr className="my-4" />
                            )}
                            {(searchList?.length > 0 || preferences?.length > 0) && (
                                <div className="text-end">
                                    <button onClick={onSubmit} className="btn btn-lg btn-primary">{getLanguageContent("prced")}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}