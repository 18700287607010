import React, { useState, lazy, useEffect } from "react"
import Tab from "../../../services/utils/tab";
import { useManageResource } from "../../../services/Context/ManageResourceContext";
import { appConst } from "../../../services/constant/constant";
import { useLanguageRes } from '../../../services/Context/LanguageContext';

const Profile = () => {

    const [tabs, setTabs] = useState([]);
    const { resourceList, getCurrentResource } = useManageResource();
    const { getLanguageContent } = useLanguageRes();

    useEffect(() => {
        const resourcesList = async () => {
            const _resourceList = getCurrentResource(appConst.resource.moduleMenu, "EQ7");
            const temp = [];
            await _resourceList?.forEach((nav) => {
                temp.push({ ...nav, id: nav?.id, component: lazy(() => import(`${nav?.url}`)), name: nav?.title, url: nav?.url })
            })
            setTabs(temp)
        };
        resourcesList();
    }, [resourceList]);



    return (
        <>
            <div className="mb-4">
                <h4 className="m-0">{getLanguageContent("propfe")}</h4>

            </div>
            <div className="row">
                <Tab tabs={tabs} tabName={"profile_tab"}  />
            </div>
        </>
    );
}

export default Profile;







