import React from "react";
import ActionStatus from "../../../services/utils/actionStatus";
import { useLanguageRes } from '../../../services/Context/LanguageContext';


export default function PharmacyList({ list, click, selected, type, postalCode, isNoresults }) {
    const { getLanguageContent } = useLanguageRes();

    return (
        <>
            {list?.length > 0 && list?.map((items, index) => (
                
                <div key={`list${index}`} onClick={() => click(items, type)} className={`d-flex justify-content-between align-items-center p-2 ${index % 2 === 0 ? "" : "bg-light"} ${selected?.id === items?.id && selected?.type == type ? "theme-border rounded" : "border border-white"}`}>
                        <div className="d-flex align-items-center pharmacy-list">
                            <div className="form-check me-2">
                                <input checked={selected?.id === items?.id && selected?.type == type} className="form-check-input" type="checkbox" value="" id={`${items?.id} ${index}`} />
                            </div>
                            <div>
                                <strong className="text-primary">{items?.name}</strong>
                                <div className="d-flex flex-wrap text-secondary">
                                    {items?.address1 && <span>{`${items?.address1}, `}</span>}
                                    {items?.city && <span>&nbsp;{`${items?.city}, `}</span>}
                                    {items?.state && <span>&nbsp;{` ${items?.state}, `}</span>}
                                    {items?.zipcode && <span>&nbsp;{` ${items?.zipcode}`}</span>}
                                    {/*items?.phoneNo && <span>&nbsp;{` ${items?.phoneNo} `}</span>*/}
                                </div>
                            </div>
                        </div>                      
                    </div>         
            ))}
            {((list == null || list?.length == 0 || !list) && (isNoresults)) && <ActionStatus description={getLanguageContent("fmnrfo")} button={""} imageid="#svg_failed" onsyncchange={""} type="search" showSvg={true} />}
        </>
    )
}