import './print.css'; // Import the external stylesheet
import React, { useContext, lazy, Suspense, useState, useEffect, useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom';
//import { serverGet } from '../../middleware/http';
import { apiConst, appConst } from '../../services/constant/constant';
import ProgressContext from '../../services/utils/progress';
//import PrintFooter from './Footer'
//import { useAccountSettings } from '../../services/Context/AccSettingContext';

//const LazyHeader = lazy(() => import('./Header'));
//const LazyContent = lazy(() => import('./Content'));

export default function PrintLayout() {
    const [data, setdata] = useState();
    //const [patient, setPatient] = useState();
    //const [account, setAccount] = useState();
    //const [printing, setPrinting] = useState();
    const { progParams } = useContext(ProgressContext);
    //const { accountDate, isGWF } = useAccountSettings();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    //const encounter_id = queryParams.get('eid');
    const type = queryParams.get('type');
    //const navigate = useNavigate();
    const iframeRef = useRef(null);


    //const getPatient = async () => {
    //    try {
    //        const res = await serverGet(apiConst.patientinfo);
    //        if (res?.status == 1) {
    //            setPatient(res?.data)
    //        }
    //    } catch (error) {
    //        console.log(error);
    //    }
    //}

    //const getUrl = (value) => {
    //    switch (value) {
    //        case 'Prescription':
    //            return `${apiConst.consultationdetail}${encounter_id}?id=${id}`
    //        case 'Lab':
    //            return `${apiConst.labdetail}${encounter_id}?id=${id}`
    //        case 'Radiology':
    //            return `${apiConst.raddetail}${encounter_id}?id=${id}`
    //        default:
    //            return
    //    }
    //}




    const getUrl = (value) => {
        const id = queryParams.get('id');

        switch (value) {
            case 'Prescription':
                return `${apiConst.prescriptionTemplateGet}${id}`
            case 'Lab':
                return `${apiConst.labTemplateGet}${id}`
            case 'Radiology':
                return `${apiConst.radiologyTemplateGet}${id}`
            default:
                return
        }
    }


    //const getEncounter = async () => {
    //    try {
    //        progParams(true);
    //        const res = await serverGet(getUrl(type));
    //        progParams(false);
    //        if (res?.status == 1 && res.data != null) {
    //            const response = res?.data[0];
    //            setdata(response);
    //            document.title = type + " " + response?.rxNo;
    //            setTimeout(() => {
    //                setPrinting(true);
    //            }, 1500);
    //        }
    //    } catch (error) {
    //        console.log(error);
    //    }
    //}

    //const initAccount = async () => {
    //    try {
    //        const res = await serverGet(apiConst.gethost);
    //        if (res?.status == 1) {
    //            setAccount(res?.data);
    //        }
    //    } catch (e) {
    //        console.log(e);
    //    }

    //}


    const getPrintTemplate = async () => {
        try {
            progParams(true);
            setdata(getUrl(type));
            iframeRef.current.onload = () => {
                progParams(false);
            }
            document.title = type;
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getPrintTemplate();
    }, [])


    //useEffect(() => {
    //    const fetchData = async () => {
    //        try {
    //            await initAccount();
    //            await getPatient();
    //            await getEncounter();
    //        } catch (error) {
    //            console.log(error);
    //        }
    //    };
    //    fetchData();
    //}, []);

    //const handleAfterPrint = () => {
    //    try {
    //        setTimeout(() => window.self.close(), 1000);
    //    } catch (e) {
    //        console.log(e);
    //    }
    //};

    //useEffect(() => {
    //    const handleAfterPrint =async () => {
    //        try {
    //            setTimeout(() => window.self.close(), 1000);
    //        } catch (e) {
    //            console.log(e);
    //        }
    //    };

    //        window.addEventListener("afterprint", (event) => {
    //            setTimeout(() => window.self.close(), 1000);
    //            handleAfterPrint();
    //        });
    //        //window.addEventListener('afterprint', handleAfterPrint);


    //    return () => {
    //        window.removeEventListener("afterprint", (event) => {
    //            setTimeout(() => window.self.close(), 1000);
    //            handleAfterPrint();

    //        });
    //       // window.removeEventListener('afterprint', handleAfterPrint);
    //    };
    //}, [printing]);

    //useEffect(() => {
    //    if (printing) {
    //        setTimeout(() => window.print(), 400);
    //    }
    //}, [printing]);
    return (
        <>
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    {/*<div className="row">*/}
                    {/*    {isGWF &&*/}
                    {/*        <div className="text-end mb-2">*/}
                    {/*            <small>*/}
                    {/*                NPI ID: 1154526218 | Phone: +1-407-630-8879 | Fax: +1-407-863-7538 | Email: info@gayawellness.org*/}
                    {/*            </small>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    <div>*/}
                    {/*        <img className="img-fluid" width='150' height='50' alt='logo' src={`/doc/getimage?url=${encodeURIComponent(account?.logo)}`} />*/}
                    {/*    </div>*/}
                    {/*    <div className="print-line"></div>*/}
                    {/*    <LazyHeader patient={patient} data={data} accountDate={accountDate} />*/}
                    {/*    <div className="text-center fs-5 p-1 title-print"><strong>{type}</strong></div>*/}
                    {/*    <LazyContent data={data} accountDate={accountDate} />*/}
                    {/*    <PrintFooter data={data} />*/}
                    {/*</div>*/}
                    <iframe ref={iframeRef} id="printframe" src={data} type="application/pdf" width="100%" height="650px" >
                    </iframe>
                </Suspense>
            </div>

        </>
    )
}

