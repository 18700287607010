import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import he from "he";
import {getAuthProfile } from '../../services/utils/validation';
import { useLanguageRes } from '../../services/Context/LanguageContext';
import { useAccountSettings } from '../../services/Context/AccSettingContext';


const NavMenu = ({ home, profile }) => {

    const { account } = useAccountSettings();
    const [navContent, setNavContent] = useState(null);
    const { getLanguageContent } = useLanguageRes();

    useEffect(() => {

        if (Array.isArray(account?.portalSettings)) {
            const data = account?.portalSettings?.find((list) => list?.code == "HRC");
            if (data) {
                const cleanHtml = DOMPurify.sanitize(data?.value);
                const decodedHTML = he.decode(cleanHtml);
                setNavContent(decodedHTML)
            }
        }

    }, [account]);


    return (
        <>
            <nav className="navbar nav-container d-lg-block justify-content-start bg-white px-3 py-2 ">
                <div className="d-flex align-items-center  w-100">
                    <div>
                        {account?.logo ? (
                            <>
                                {home?.url?.includes("https://") ? <a href={`${home?.url}`}  className="navbar-brand">
                                    <img src={'/doc/getimage?url=' + encodeURIComponent(account?.logo)} height="60" alt="logo" />
                                </a> : <Link className="navbar-brand" to={`${home?.url}`}>
                                    <img src={'/doc/getimage?url=' + encodeURIComponent(account?.logo)} height="60" alt="logo" />
                                </Link>
                                }
                            </>)
                            : (
                                <>
                                    {home?.url?.includes("https://") ?
                                        <a href={`${home?.url}`} className="navbar-brand">
                                            <h3>{account?.name}</h3>
                                        </a> :
                                        <Link className="text-decoration-none text-dark" to={`${home?.url}`}>
                                            <h3>{account?.name}</h3>
                                        </Link>
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className="d-none d-md-flex flex-grow-1 justify-content-end" dangerouslySetInnerHTML={{ __html: navContent }}>

                    </div>
                    <div className="align-self-center ms-auto">
                        {!getAuthProfile.getProfileAuth() && window.location.pathname !== "/security/login" && window.location.pathname != "/activateaccount" ?
                            <Link to="/security/login">
                                <button className="btn btn-primary px-4 rounded-pill">{getLanguageContent("signin")}</button>
                            </Link>
                            : ""}

                        {profile?.resProfile.firstName && (
                            <Link to="/dashboard" title="Dashboard">
                                <svg className="nav_icons" width="28" height="28" role="img"><use xlinkHref="#svg_dashboard" /></svg>
                            </Link>
                        )}
                        
                    </div>
                </div>
                
            </nav>
        </>
    );
}

export default NavMenu;


