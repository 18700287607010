import React, { useState, useEffect, forwardRef } from "react";
import { Controller } from 'react-hook-form';
import { getMsg, formValidation, splitCountryCode } from "./validation";
import { useAccountSettings } from "../Context/AccSettingContext";


export const PasswordField = ({ name, label, register, errors, validationSchema, icon, placeholder, autoFocus }) => {

    const [password, setPassword] = useState(false)

    const togglePassword = ((e) => {
        e.preventDefault();
        setPassword(!password)

    });
    return (
        <>
            <div className="position-relative">
                <label htmlFor={name} className="form-label">{label}<span className="text-danger">*</span></label>
                <input autoFocus={autoFocus ? autoFocus : false} type={password ? "text" : "password"} className="form-control body-secondary-color" id={name} placeholder={placeholder ? placeholder : "Enter password"} {...register(name, validationSchema)} />
                {icon &&
                    <div className="position-absolute top-50 end-0 mx-2 p-1">
                        <span role="button" onClick={togglePassword}>{password ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                        </svg>}</span>
                    </div>
                }
            </div>
            {errors[name]?.message && <p className="text-danger">{errors[name].message}</p>}
        </>
    );
}


export const InputField = ({ name, label, register, errors, validationSchema, type, placeHolder, required }) => {

    return (
        <div className="my-4">
            <label htmlFor={name} className="form-label">{label} <span className="text-danger">{required && "*"}</span></label>
            <input type={type} className="form-control" id={name} placeholder={placeHolder} {...register(name, validationSchema)} />
            {errors[name]?.message && <p className="text-danger">{errors[name].message}</p>}
        </div>

    );
}



export const PhoneInputField = ({ control, name, label, placeholder, required, setValue, countryCode, diallingName, autoFocus, defaultNumber }) => {

    const { countryList } = useAccountSettings();


    const handleCountryChange = (value) => {
        setValue(diallingName, value.isdCode);
    };


    useEffect(() => {
        if (defaultNumber) {
            var code = splitCountryCode(defaultNumber, countryCode, countryList);
            setValue(diallingName, code.isdCode);
            setValue(name, code.phoneNumber);
        }
    }, [countryList, defaultNumber]);

    return (
        <>
            {Array.isArray(countryList) && countryList?.length > 0 && (
                <div className="form-group">
                    <label htmlFor={name} className="form-label">
                        {label}
                        {required && <span className="text-danger star">*</span>}
                    </label>

                    <div className="input-group">
                        <Controller
                            control={control}
                            name={diallingName}
                            defaultValue={countryCode}
                            render={({ field }) => (
                                <>
                                    <button
                                        className="btn btn-outline-secondary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style={{ zIndex: 'auto', border:'var(--bs-border-width) solid var(--bs-border-color)' }}
                                        {...field}
                                    >
                                        {field.value ? field.value : countryCode}
                                    </button>
                                    <ul className="dropdown-menu vertical_scroll" aria-labelledby="dropdownMenuButton" style={{ maxHeight: '200px', overflowY: 'auto', width: '100%' }}>
                                        {countryList?.map((value, index) => (
                                            <li key={index}>
                                                <a className="dropdown-item" rel="noopener noreferrer" onClick={() => handleCountryChange(value, diallingName)} >
                                                    {value.name} {value.isdCode}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        />

                        <Controller
                            control={control}
                            name={name}
                            defaultValue=""
                            rules={{
                                required: required ? getMsg(301) : false,
                                pattern: formValidation.phonenumber.pattern,
                                maxLength: { value: 15, message: getMsg("305") },
                                minLength: { value: 10, message: getMsg("304") },

                            }}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="tel"
                                    className="form-control"
                                    id={name}
                                    aria-label={label}
                                    placeholder={placeholder}
                                    value={field.value || ''}
                                    autoFocus={autoFocus}
                                />
                            )}
                        />
                    </div>
                </div>
            )}
        </>
    );
};


