import React, { useState } from 'react';

export default function ExternalOrderCreate() {
   

    const handleSubmit = (event) => {
      
    }
    //console.log(sampleDoc);
    return (
        <>
        </>
    )
}