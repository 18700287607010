import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { serverGet } from '../../middleware/http';
import { apiConst } from '../constant/constant';
import ProgressContext from '../utils/progress';


const EncounterContext = createContext(null);

export const useEncounter = () => {
    return useContext(EncounterContext);
};


export const EncounterProvider = ({ children }) => {
    const [encounterContext, setEncounterContext] = useState(null);
    const [currentEnc, setCurrentEnc] = useState(null);

    const location = useLocation();
    const { progParams } = useContext(ProgressContext);



    const encounterList =  () => {
        progParams(true);
         serverGet(`${apiConst.getschedulelist}`).then((res) => {
            progParams(false);
            try {
                if ( res?.status == 1) {
                    const response = res?.data;
                    let closestDate = null;
                    let closestTimeDifference = Infinity;

                    response?.forEach((dateObj) => {                      
                        const convertDatetime = (datetime) => {
                            return new Date(datetime + 'Z');
                        };
                        const givenDateTime = convertDatetime(dateObj?.scheduleOn);
                        dateObj.scheduleOn = givenDateTime;
                        const currentTime = new Date();
                        const timeDifferenceInMillis = givenDateTime - currentTime;
                        const minuteDifference = Math.floor(timeDifferenceInMillis / (1000 * 60));
                        if (minuteDifference < closestTimeDifference && minuteDifference >= 0) {
                            closestDate = dateObj;
                            closestTimeDifference = minuteDifference;
                        }

                        //console.log(minuteDifference);
                    });

                    setCurrentEnc(closestDate);

                    //console.log('Closest date object:', closestDate);

                    //console.log(response);

                    if (res?.data == null) {
                        setEncounterContext(true);
                    } else {
                        setEncounterContext(response);
                    }

                }
                    
            } catch (e) {
                console.log(e);
            }
        })
    }


    useEffect(() => {      

        if (!location.pathname.includes("security")) {

            if (!encounterContext) {
                //encounterList();
            }
            
        }
                   
    }, []);


    return (
        <EncounterContext.Provider value={{ currentEnc, encounterList }}>
            {children}
        </EncounterContext.Provider>
    );
};



