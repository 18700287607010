import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Forms from "../forms";
import { apiConst, appConst } from "../../../../../services/constant/constant";
import { getAuthProfile, getSearchParams } from "../../../../../services/utils/validation";
import { serverGet } from "../../../../../middleware/http";
import ActionStatus from "../../../../../services/utils/actionStatus";
import ScheduleHeader from "../../summary/ScheduleHeader";
import { useAccountSettings } from "../../../../../services/Context/AccSettingContext";
import { useLanguageRes } from "../../../../../services/Context/LanguageContext";

export default function DirectForm() {
    const [formsList, setFormsList] = useState();
    const [form, setForm] = useState(null);
    const [show, setShow] = useState({ form: false, submit: false, reSubmit: false });
    const { getLanguageContent } = useLanguageRes();

    //const navigate = useNavigate();

    const getForms = async (formId, patientMemberId) => {
        try {
            let encounter_id = getSearchParams("eid");
            let fmid = getSearchParams("fmid"); // family Member Id
            let speciality_id = getSearchParams("spid"); // family Member Id

            const url = `${apiConst.formsList}${fmid ? fmid : patientMemberId}?areaid=${6}${encounter_id ? `&eid=${encounter_id}` : ""}${speciality_id ? `&sid=${speciality_id}` : ""}`

            const res = await serverGet(url);
            if (res?.data != null && res.status == 1) {
                checkAccess(res?.data, formId);
                setFormsList(res?.data);
            }
        } catch (error) {
            console.log(error);
        } finally {

        }

    }



    const checkAccess = async (list, id) => {
        const temp = list?.find((l) => l?.formId == id?.toLowerCase()); // Check is form exist on list
        if (temp) {
            if (temp?.formInputId) {
                handlePublicForms("reSubmit");
            } else if (temp?.isSecure) {
                let auth_res = getAuthProfile.getProfileAuth();
                if (auth_res == null || auth_res == undefined) {
                    const res = await serverGet(apiConst.getauthprofile);
                    if (res?.data?.auth) getAuthProfile.saveProfileAuth();
                    auth_res = res?.data?.auth;
                }

                if (!auth_res || auth_res == "false") {
                    const patient_res = await serverGet(apiConst.patientinfo);

                    //return navigate(`/security/login?c=y`)
                } else {
                    handlePublicForms("form");
                }
            } else {
                if (!temp?.isSecure && !temp?.formInputId)
                    handlePublicForms("form");
            }

            setForm(temp);
        }
    }


    const handlePublicForms = (name) => {
        const temp = { ...show };
        Object.keys(temp).map((list) => {
            if (name == list) {
                temp[list] = true;
            } else {
                temp[list] = false;
            }
        });

        setShow(temp)

    }

    //console.log(show);

    useEffect(() => {
        const _id = getSearchParams("frmid") // Form id
        const _pmId = getSearchParams("pmid"); // PATIENT MEMBER ID

        if (_id && _pmId) {
            getForms(_id, _pmId);
        }

    }, [])



    useEffect(() => {
        if (form?.clientTitle) {
            window.document.title = form?.clientTitle;
        }
    }, [form])



    return (
        <>
            <div>
                {show.form ? (
                    <>
                        <ScheduleHeader title={form?.clientTitle} show={"No"} />

                        <Forms level="direct-forms" formShowState={true} areaId={appConst.areaId.directForms} handlePublicForms={handlePublicForms} list={formsList} />
                    </>
                ) :
                    (
                        <>
                            <InfoPage view={show} getLanguageContent={getLanguageContent} />
                        </>
                    )}
            </div>
        </>
    )
}



const InfoPage = ({ view, getLanguageContent }) => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "78.5vh" }}>
                <div className="text-center my-auto">
                    {view?.submit ? (
                        <>
                            <Layout fill="#57C032" width="80" height="80" imageid="#icons_success" description={getLanguageContent("frmtsp")} getLanguageContent={getLanguageContent} />
                        </>
                    ) :
                        view?.reSubmit ? (
                            <>
                                <Layout fill="#f0ad4e" width="100" height="100" imageid="#icon_exlamation" description={getLanguageContent("frmsbm")} getLanguageContent={getLanguageContent} />
                            </>
                        ) : (
                            <>
                                <Layout getLanguageContent={getLanguageContent} />
                            </>
                        )}
                </div>
            </div>
        </>
    )
}


const Layout = ({ fill, imageid, width, height, description, getLanguageContent }) => {
    const { homeUrl } = useAccountSettings();

    return (
        <>
            {imageid && <ActionStatus description={""} button={""} fill={fill} width={width} height={height} imageid={imageid} onsyncchange={""} type="search" showSvg={true} />}
            {description && <p className="my-3 fs-4">{description}</p>}
            <>
                {homeUrl?.includes("https://") ? <a href={`${homeUrl}`} className="navbar-brand text-decoration-underline">
                    {getLanguageContent("frmbck")}
                </a> : <Link className="navbar-brand text-decoration-underline" to={`${homeUrl}`}>
                    {getLanguageContent("frmbck")}
                </Link>
                }
            </>

        </>
    )
}