import React from 'react'

export default function AppointmentTypes({ title, showBtn, description, btn, booknow, type }) {
    return (

        <div className="card border-0 p-2 w-100">
            <div className="card-body pb-0">
                <h4>{title}</h4>
                <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </div>
            <div className="card-footer bg-white border-0 pb-3">
                {showBtn && (
                    <button onClick={() => booknow(type)} type="button" className="btn btn-primary btn-lg">{btn}</button>
                )}
            </div>
        </div>
    )
}
