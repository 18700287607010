import React, { useEffect } from "react";
import { Link } from "react-router-dom";




export default function PageNotFound() {
    useEffect(() => {
        document.title = "Page not found"
    },[])
    return (
        <div className="bg-white rounded d-flex justify-content-center align-items-center " style={{ minHeight: "100vh" }}>
            <div className="text-center">
                <h1 className="text-danger">404</h1>
                <h3 className="h2">
                    Looks like you're lost
                </h3>
                <p>The page you are looking for is not available</p>
                <Link to="/">
                    <h6>Back to home<span>
                        <svg className="ms-1" width="17" height="17" role="img"><use xlinkHref="#svg_tabactive" /></svg>
                    </span></h6>
                </Link>
            </div>
        </div>
    )
}