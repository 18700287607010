import React, { useContext, useEffect, useState, useRef } from "react";
import { serverGet } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import ProgressContext from "../../../services/utils/progress";
import { ecnSession } from "../../../services/utils/validation";
import { useLanguageRes } from '../../../services/Context/LanguageContext';


export default function PaymentProcess() {
    const { progParams } = useContext(ProgressContext);

    const [formdata, setformdata] = useState(null);
    const formRef = useRef(null);
    //const sessionStore = ecnSession();
    const { getLanguageContent } = useLanguageRes();


    const getPayment = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const sessionStore = ecnSession();

        const enc_id = urlSearchParams.get('eid');
        const ext_plan_id = urlSearchParams.get('planId');
        const fmid = urlSearchParams.get('fmid');
        const member_id = localStorage.getItem("memberId");
        const planid = sessionStore?.planId;
        const plandetailid = sessionStore?.planDetailId;
        const itemId = sessionStore?.itemId;



        //progParams(true);
        serverGet(`${apiConst.proceespayment}${fmid ? fmid : member_id}/${enc_id}${planid ? `?planid=${planid}`: ''}`).then((res) => {
            //progParams(false);
            try {
                let _res = res;
                if (ext_plan_id) _res.planId = ext_plan_id
                setformdata(_res);
            } catch (e) {
                console.log(e);
            }
        })
    }
    const submitForm = () => {
        //console.log("submit")
        if (formRef.current) {

            formRef?.current?.submit();
        }
    };

    useEffect(() => {
        document.title = "Processing";

        getPayment();
    }, [])

    useEffect(() => {

        if (formdata?.CustomerName &&
            formdata?.ClientId && formdata?.RedirectUrl &&
            formdata?.Amount?.toString() && formdata?.OrderID &&
            formdata?.ReturnUrl && formdata?.Currency &&
            formdata?.CancelUrl &&
            formdata?.CurrencyDisplayFormat
        ) {
            setTimeout(() => {
                submitForm();
            }, 4000);
        }
    }, [formdata]);


    return (
        <>
            <div className="content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="card success-card">
                                <div className="card-body">
                                    <form ref={formRef} name='Payform' action={formdata?.RedirectUrl} method='post'>
                                        <input type='hidden' name='ClientId' value={formdata?.ClientId} />
                                        <input type='hidden' name='CustomerName' value={formdata?.CustomerName} />
                                        <input type='hidden' name='Email' value={formdata?.Email} />
                                        <input type='hidden' name='RefId' value={formdata?.RefId} />
                                        <input type='hidden' name='OrderID' value={formdata?.OrderID} />
                                        <input type='hidden' name='Amount' value={formdata?.Amount} />
                                        <input type='hidden' name='Currency' value={formdata?.Currency} />
                                        <input type='hidden' name='ReturnUrl' value={formdata?.ReturnUrl} />
                                        <input type='hidden' name='CancelUrl' value={formdata?.CancelUrl} />
                                        <input type='hidden' name='CustomerAddress' value={formdata?.CustomerAddress} />
                                        <input type='hidden' name='Phone' value={formdata?.Phone} />
                                        <input type='hidden' name='CurrencyDisplayFormat' value={formdata?.CurrencyDisplayFormat} />
                                        {formdata?.planId && <input type='hidden' name='PlanId' value={formdata?.planId} />}
                                        <h3 className="text-center"><i className="fa fa-spinner fa-spin me-1"></i>{getLanguageContent("pplswa")}</h3>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}