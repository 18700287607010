import React, { useContext, useEffect, useRef, useState } from 'react';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import { getSearchParams } from '../../../services/utils/validation';
import ProgressContext from '../../../services/utils/progress';

export default function DocumentsViewer() {
    const [previewUrl, setPreviewUrl] = useState("");
    const { progParams } = useContext(ProgressContext);
    const iframeRef = useRef(null);

    const getDoc = () => {
        const docId = getSearchParams("id")
        progParams(true);
        serverGet(`${apiConst.docViewer}${docId}`).then((res) => {
            progParams(false);
            try {
                if (res.data != null && res?.status == 1) {
                    setPreviewUrl(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }


    useEffect(() => {

        getDoc();
        if (iframeRef != null) {
            iframeRef.current.onload = () => {
                progParams(false);
            }
        }
          
    }, [])


    return (
        <>
            <iframe ref={iframeRef} id="printframe" src={previewUrl} width="100%" height="650px" title="doc-viewer" />
        </>
    )
}