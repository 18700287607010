import React, { createContext, useState, useContext, useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { serverGet } from '../../middleware/http';
import { apiConst } from '../constant/constant';
import { getAuthProfile, getSearchParams } from '../utils/validation';
import { useProfile } from '../utils/profileContext';

const AccountSettingContext = createContext(null);

export const useAccountSettings = () => {
    return useContext(AccountSettingContext);
};

const accountSettingCode = "DTF,DIPR,CUR,PMTD,GWF,URS,ENSP,ENJN,BECON,AFCON,VCP,BNWTP,CPI,SCL,PHF,PMA,ECI,PFS,PIF,FUA,CIA,ACG,FCI,HMP,AAA,DCS,PCW,ADN,SNP,CBT,PSR,PAT";

export const AccountSettingsProvider = ({ children }) => {
    const [account, setAccount] = useState(null);
    const [isAuth, setisAuth,] = useState(null);
    const [accountSettings, setAccountSettings] = useState(null);
    const [accountDate, setAccountDate] = useState(null);
    const [showAccPricing, setShowAccPricing] = useState(false);
    const [AccountCurrency, setAccountCurrency] = useState(null);
    const [cateogrySettings, setCateogrySettings] = useState(null);
    const [isGWF, setisGWF] = useState(false);
    const [patientMinimumAge, setPatientMinimumAge] = useState("");
    const [conditionValue, setConditionValue] = useState(null); // Reasonn page conditions
    const [conditionDocLength, setConditionDocLength] = useState(null); // Reasonn page conditions
    const [schedulePricing, setSchedulePricing] = useState(null);
    const [accountModules, setAccountModules] = useState(null);
    const [calenderWeeks, setCalenderWeeks] = useState(null);
    const [accountCountry, setAccountCountry] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [homeUrl, setHomeUrl] = useState(null);
    const location = useLocation();
    const { handleAuth } = useProfile();
    //  console.log(patientInfo)

    const getAccountSetting = async () => {
        try {
            const res = await serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(accountSettingCode)}`)
            if (res?.status == 1 && res?.data != null) {
                if (Array.isArray(res?.data)) {
                    const dateSetting = res?.data?.find(em => em?.settingCode == "DTF");
                    if (dateSetting)
                        setAccountDate(dateSetting);
                    const priceSetting = res?.data?.find(em => em?.settingCode == "DIPR" && em?.value == "1");
                    if (priceSetting)
                        setShowAccPricing(true);
                    const account_currency = res?.data?.find(em => em?.settingCode == "CUR");
                    if (account_currency)
                        setAccountCurrency(account_currency);
                    const gwf = res?.data?.find(em => em?.settingCode == "GWF" && em?.value == "1"); //Gaya weelness workflow
                    if (gwf) setisGWF(true)
                    const pma = res?.data?.find(em => em?.settingCode == "PMA")?.value; // patient minimum age 
                    if (pma) setPatientMinimumAge(parseInt(pma));
                    const reasonPageCondition = res?.data?.find(em => em?.settingCode == "FCI")?.value; // Reason doc 
                    if (reasonPageCondition) setConditionValue(reasonPageCondition);
                    const maxDocLength = res?.data?.find(em => em?.settingCode == "HMP")?.value; // condition  maximum docs  
                    if (maxDocLength) setConditionDocLength(maxDocLength);
                    const _calendarWeeks = res?.data?.find(em => em?.settingCode == "PCW")?.value;
                    if (_calendarWeeks) setCalenderWeeks(_calendarWeeks)
                    setAccountSettings(res?.data);
                    return res?.data;
                }
            }
        } catch (e) {
            console.error(e)
        }

    }

    const getAccountCountry = async () => {
        try {
            const res = await serverGet(`${apiConst.getAccountCountry}`);
            if (res?.status == 1) {
                setAccountCountry(res?.data?.isdCode);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getCountryList = async () => {
        try {
            const res = await serverGet(`${apiConst.getCountryList}`);
            if (res?.status == 1) {
                setCountryList(res.data);

            }
        } catch (e) {
            console.log(e);
        }


    };
    const initAccount = async () => {
        try {
            const settingCode = "HRC,HPC,FTC,DFP"
            const res = await serverGet(`${apiConst.gethost}?scodes=${settingCode}`);
            if (res?.status == 1) {
                document.title = res?.data?.name || "";
                let codes = (res?.data?.portalSettings?.filter(obj => obj.code === "THM")[0]?.value || "")?.split("~");
                if (codes?.length >= 4) {
                    var styles = {
                        "primary-color": codes[0],
                        "accent-color": codes[1],
                        "primary-color-rgb": codes[2],
                        "accent-color-rgb": codes[3],
                        "bs-body-font-family": !codes[4] ? "'Nunito', sans-serif" : codes[4].split("|")[1],
                    };

                    let _headerFooterBg;


                    if (Array.isArray(codes) && codes.length > 5) {
                        _headerFooterBg = JSON.parse(codes[5]);
                    } else {
                        _headerFooterBg = {};
                    }
                    setHeaderAndFooter(_headerFooterBg, styles["primary-color-rgb"]);

                    Object.keys(styles).forEach(function (key) {
                        document.documentElement.style.setProperty('--' + key, styles[key]);
                    })
                    var url = !codes[4] ? "https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap" : codes[4].split("|")[0];

                    loadCSS(url);
                }

                setAccount(res?.data);
                setFavicon(res?.data?.favicon);

             
            }
        } catch (e) {
            console.log(e);
        }

    }

    const loadCSS = (href) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        document.head.appendChild(link);
    };

    const setHeaderAndFooter = (value, defaultValue) => {

        const returnJoinvalues = (_value) => {
            if (Array.isArray(_value) && _value?.length == 3) {
                return _value?.join(",")
            }
            return false;
        }

        const styles = {
            "header-bg-color-rgb": returnJoinvalues(value?.headerBGRGB) || "255,255,255",
            "header-color": returnJoinvalues(value?.headerFontRGB) || "",
            "footer-bg-color-rgb": returnJoinvalues(value?.footerBGRGB) || defaultValue,
            "footer-color": returnJoinvalues(value?.footerFontRGB) || "255,255,255"
        };


        Object.entries(styles).forEach(([key, val]) => {
            document.documentElement.style.setProperty(`--${key}`, val);
        });

    }

    const setFavicon = (path) => {
        const link = document.querySelector("link[rel~='icon']");
        let _url = `/favicon.png`;
        if (path) {
            _url = `/doc/getimage?url=${encodeURIComponent(path)}`
        }
        if (!link) {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = _url;
            document.head.appendChild(newLink);
        } else {
            link.href = _url;
        }


    };


    const getAccountsettingValue = (arr, code) => {

        if (Array.isArray(arr)) {
            return arr?.find((list) => list?.settingCode == code)?.value
        }
    }

    const getAuth = async () => {
        try {
            const res = await serverGet(apiConst.getauthprofile);
            if (res?.status == 1) {
                if (res?.data?.auth) {
                    localStorage.setItem("memberId", res?.data?.memberId)
                    getAuthProfile.saveProfileAuth();
                    handleAuth(true);
                    getAccountCountry();
                    getCountryList();
                } else {
                    getAuthProfile.clearProfileAuth();
                }
                setisAuth(res?.data?.auth);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCateogry = () => {

        const account_settings = 'schedule_duration,encounter_mode,booking_type,schedulerecurringfrequency_type,weekdays_type,weekordinal_type,communication_mode';
        serverGet(`${apiConst.getmodes}${account_settings}`).then((res) => {

            try {
                if (res?.data != null) {
                    setCateogrySettings(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const getSchedulePricing = (sId, cId, memberId) => {
        const pricingAtr = [
            {
                attributeid: "101", //CONSULTATION ID
                recordtypeid: "33",
                recordid: cId ? cId : "1"
            },
            {
                attributeid: "102", //SPECIALTY  ID 
                recordtypeid: "32",
                recordid: sId
            }
        ]

        if (memberId) {
            pricingAtr.push({
                attributeid: "103", //PROVIDER MEMBER ID 
                recordtypeid: "4",
                recordid: memberId
            })
        }

        serverGet(`${apiConst.getAdvancePricing}?atr=${JSON.stringify(pricingAtr)}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data != null) {
                    setSchedulePricing(res?.data?.price)
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const getAccountModules = (id) => {
        serverGet(`${apiConst.getAccountModules}${id}`).then((res) => {
            try {
                if (res?.status == 1 && res?.data != null) {
                    setAccountModules(res?.data)
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const getResCode = async () => {
        try {
            const res = await serverGet(apiConst.getresponsecode + "/en-us");
            if (res?.data != null && res?.status == 1) {
                localStorage.setItem("errorMsg", JSON.stringify(res?.data));
            }
        } catch (error) {
            console.error(error);
        }
    }

    const updateHomeUrl = (url) => {
        if (url) {
            setHomeUrl(url);
        }
    }

    useLayoutEffect(() => {
        getAuth();
        getResCode();

    },[])

    useLayoutEffect(() => {
        if (!accountSettings) {
            const fetchData = async () => {
                try {
                    await getAccountSetting();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }

        if (!account)
            initAccount();
    }, [location]);


    useEffect(() => {
        if (isAuth) {
            getAuthProfile.saveProfileAuth();
        }
    }, [isAuth])

    //useEffect(() => {
    //    getResCode();
    //}, [])

    useEffect(() => {

        const _typeId = getSearchParams("ftypeid");
        const specialityId = getSearchParams("spid");
        const _memberId = getSearchParams("memberid");

        if (specialityId && getAuthProfile.getProfileAuth()) {
            getSchedulePricing(specialityId, _typeId, _memberId);
        }

    }, [location])

    useEffect(() => {
        setTimeout(() => {
            if (isAuth && !cateogrySettings) {
                getCateogry();
            }
        }, 1000)


    }, [location, isAuth])

    useEffect(() => {
        setTimeout(() => {
            if (isAuth && !accountModules && account?.accountId) {
                getAccountModules(account?.accountId);
            }
        }, 1000)


    }, [location, isAuth, account])


    return (
        <AccountSettingContext.Provider value={
            {
                accountDate,
                showAccPricing,
                AccountCurrency,
                isGWF,
                accountSettings,
                account,
                patientMinimumAge,
                cateogrySettings,
                conditionValue,
                conditionDocLength,
                schedulePricing,
                getSchedulePricing,
                accountModules,
                getAccountSetting,
                calenderWeeks,
                getAccountsettingValue,
                accountCountry,
                updateHomeUrl,
                homeUrl,
                countryList,
                isAuth
            }
        }
        >
            {children}
        </AccountSettingContext.Provider>
    );
};



