import React, { useContext, useEffect } from "react"
import * as bootstrap from 'bootstrap';
import { useManageResource } from "../../../services/Context/ManageResourceContext";
import { useState } from "react";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import ProgressContext from "../../../services/utils/progress";

export default function TermsAndPolicy() {

    const { resourceList, getCurrentResource, portalResourceContent } = useManageResource();
    const [contentResource, setContentResource] = useState(null)
    const [termsAndCondition, setTermsAndCondition] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const { getLanguageContent } = useLanguageRes();
    const { progParams } = useContext(ProgressContext);


    const getLabel = (arr, code) => {
        return arr?.find((list) => list?.code == code)?.title
        //return code;
    }

    function openModal(code) {
        setModalContent(null);
        progParams(true);
        const modal = new bootstrap.Modal(document.getElementById("terms-policy"));
        if (modal) {

            if (code == "TC2") {
                setModalContent(termsAndCondition)
            } else if (code == "PP5") {
                setModalContent(privacyPolicy)
            }

            setTimeout(() => {
                modal.show();
            }, 2000)
        }
        setTimeout(() => {
            progParams(false);
        }, 1600)

    };


    //console.log(modalContent);

    useEffect(() => {
        const termsAndPolicy = getCurrentResource("7");
        const _terms = portalResourceContent?.find((list) => termsAndPolicy?.find((item) => item?.id == list?.resourceId && item?.code == "TC2"))
        const _policy = portalResourceContent?.find((list) => termsAndPolicy?.find((item) => item?.id == list?.resourceId && item?.code == "PP5"))

        setContentResource(termsAndPolicy);

        //console.log(_terms)
        if (_terms) {
            setTermsAndCondition(_terms)
        }

        if (_policy) {
            setPrivacyPolicy(_policy)
        }
    }, [resourceList, portalResourceContent])


    return (
        <>
            <span>
                {termsAndCondition && <span className="text-primary pointer-class" onClick={() => openModal("TC2")}> {getLabel(contentResource, "TC2")}</span>}
                {(termsAndCondition && privacyPolicy) && <span> {getLanguageContent("acprpo")} </span>}
                {privacyPolicy && <span className="text-primary pointer-class" onClick={() => openModal("PP5")}>{getLabel(contentResource, "PP5")}</span>}
            </span>

            <div className="modal fade" id="terms-policy" aria-hidden="true" aria-labelledby="terms-policy" tabindex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-3 text-center w-100" id="terms-policy">{modalContent?.pageTitle}</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={{ __html: modalContent?.content }}>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}