import { createContext, useRef, useState } from "react";
import * as bootstrap from 'bootstrap';
import { Toast } from 'bootstrap';
import { getMsg } from "../utils/validation";

const NotifyContext = createContext(null)

export default NotifyContext

export function NotifyContextProvider({ children }) {
    const [option, setOption] = useState()

    const notifyRef = useRef();

    const toastMessage = (code, status = undefined, title = undefined, obj) => {
        //console.log(title)
        title = title == undefined ? "" : title;
        status = status == undefined ? notifyStatus.danger : status;
        // console.log(obj);
        const _code = parseInt(code);

        if (_code >= 100 && _code < 200)
            status = notifyStatus.success
        showToast(getMsg(code), status, title, notifyType.toast, obj)
    }

    const alertMessage = (code, status, title) => {
        title = title == undefined ? "" : title;
        status = status == undefined ? notifyStatus.danger : status;

        const _code = parseInt(code);

        if (_code >= 100 && _code < 200)
            status = notifyStatus.success

        showToast(getMsg(code), status, title, notifyType.alert)
    }


    const showToast = (desc, status, title, type, obj) => {
        let temp = {
            desc: desc,
            status: status,
            title: title,
            type: type
        }

        if (obj && desc) {
            temp.desc = replacePlaceholders(obj, desc)
        }
        setOption(temp);
        var myToast = notifyRef.current;
        var bsToast = bootstrap.Toast.getInstance(myToast);
        bsToast = new Toast(myToast, { autohide: true, delay: 3000 });
        setTimeout(() => {
            bsToast?.show();
        }, 100)
    }

    function replacePlaceholders(obj, str) {
        return str.replace(/{(\w+)}/g, (match, key) => {
            return obj.hasOwnProperty(key) ? obj[key] : match;
        });
    }

    const closeToast = () => {
        var myToast = notifyRef.current
        var bsToast = bootstrap.Toast.getInstance(myToast)
        bsToast?.hide()

    }
    const alertColor = (type) => {
        switch (type) {
            case 'info':
                return 'alert-info'
            case 'primary':
                return 'alert-primary'
            case 'secondary':
                return 'alert-secondary'
            case 'success':
                return 'alert-success'
            case 'warning':
                return 'alert-warning'
            case 'light':
                return 'alert-light'
            case 'dark':
                return 'alert-dark'
            default:
                return 'alert-danger'
        }
    }

    const toastColor = (type) => {
        switch (type) {
            case 'info':
                return 'text-white bg-info'
            case 'primary':
                return 'text-white bg-primary'
            case 'secondary':
                return 'text-white bg-secondary'
            case 'success':
                return 'text-white bg-success'
            case 'warning':
                return 'text-dark bg-warning'
            case 'light':
                return 'text-white bg-light'
            case 'dark':
                return 'text-white bg-dark'
            case 'danger':
                return 'text-white bg-danger'
            default:
                return ''
        }
    }
    return (
        <NotifyContext.Provider value={{ notifyParams: showToast, notifyToast: toastMessage, notifyAlert: alertMessage, setShow: closeToast, }}>
            {children}
            <div className="position-fixed top-0 end-0 m-4">
                {
                    //option?.type == 'toast' ?
                    <div style={{
                        minWidth: "250px",
                        width: "auto"
                    }} className={`toast ${toastColor(option?.status)}`} role="alert" ref={notifyRef} id="bs-toast">
                        {
                            option?.title == '' || option?.title == undefined ? "" :
                                <div className="toast-header">
                                    <strong className="me-auto">{option?.title}</strong>
                                    <button type="button" className="btn-close" onClick={() => closeToast()} aria-label="Close"></button>
                                </div>
                        }

                        {
                            option?.title == '' || option?.title == undefined ?
                                <div className="d-flex">
                                    <div className="toast-body textfield-wrap">
                                        {option?.desc == '' ? '' : option?.desc}
                                    </div>
                                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                                </div> :
                                <div className="toast-body textfield-wrap">
                                    {option?.desc == '' ? '' : option?.desc}
                                </div>
                        }
                    </div>

                    //: option?.type == 'alert' ?
                    //    <div className={"alert alert-dismissible fade" + ' ' + alertColor(option?.status)} ref={notifyRef} role="alert" id="bs-alert"> {option?.desc}
                    //        <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={() => closeToast()} aria-label="Close"></button>
                    //    </div> : null
                }
            </div>

        </NotifyContext.Provider>
    );
}

export const notifyType = {
    toast: 'toast',
    alert: 'alert',
}

export const notifyStatus = {
    info: 'info',
    danger: 'danger',
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    warning: 'warning',
    light: 'light',
    dark: 'dark'
}
