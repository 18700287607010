import React from "react";
import { useNavigate } from "react-router-dom";
//import { useAccountSettings } from "../../../../services/Context/AccSettingContext";
//import { useProfile } from "../../../../services/utils/profileContext";
//import {  getAuthProfile, getSearchParams, isEnabled } from "../../../../services/utils/validation";

export default function ScheduleHeader({ title, description, btd, show }) { // back to dashboard 

    const navigate = useNavigate();
    //const { accountSettings } = useAccountSettings();
    //const { patientInfo } = useProfile();


    const handleBack = () => {
        if (btd) {
            return navigate("/dashboard");
        } else {
            return navigate(-1);
        }

    }

    return (
        <>
            <div className="my-2">
                <h3>
                    {(window.location.pathname !== "/" && show != "No") &&
                        <span className="pointer-class me-2" onClick={() => {
                            handleBack();
                        }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                        </span>
                    }
                    {title}
                </h3>
                {description && (
                    <small>{description}</small>
                )}
            </div>
        </>
    )
}

