import { useContext, useEffect, useState } from "react"
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { apiConst } from '../../../services/constant/constant'
import { serverPost } from '../../../middleware/http'
import { formValidation, getMsg} from '../../../services/utils/validation';
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { useLanguageRes } from '../../../services/Context/LanguageContext';

const ForgotPassword = () => {
    const { register,handleSubmit,watch,formState: { errors },} = useForm();
    const [resetLink, setResetLink] = useState(false)
    const { notifyToast } = useContext(NotifyContext)
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    var resetlinkmsg = getLanguageContent("resetlink");
    resetlinkmsg = resetlinkmsg.replace("{email}", watch("Email"));

   
    const onSubmit = ((data) =>  {      
        progParams(true)
        serverPost(apiConst.postforgotpassword + "/" + encodeURIComponent(data.Email), data).then((res) => {
            progParams(false)            
            try {
                if (res?.status === 1) {
                    setResetLink(true)
                }                    
                else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        })        
    });

    useEffect(() => {
        document.title = 'Forgot password';
    }, []);

    
    return (
        <>
            <h6 className="card-title text-uppercase fw-bold">{getLanguageContent("rstpsswrd")}</h6>
            {resetLink &&
                <div className="text-center">
                    <svg className="nav_icon mb-2" width="150" height="150" role="img" aria-label="Dashboard"><use xlinkHref="#svg_successpage" /></svg>
                    <p className="fw-bold">{resetlinkmsg}</p>
                    <p><small>{getLanguageContent("chkemail")}</small></p>
                </div>
            }
            {!resetLink && <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-4">
                        <p><small>{getLanguageContent("resetpsswrd")}</small></p>
                        <label htmlFor="txtEmail" className="form-label">{getLanguageContent("email")}<span className="text-danger">*</span></label>
                        <input autoFocus={true}
                            type="text"
                            className="form-control body-secondary-color" id="txtEmail"
                            placeholder={getLanguageContent("entemail")}
                            {...register("Email",
                                {
                                    required: getMsg(301),
                                    pattern: formValidation.email.pattern
                                })}
                        />
                        {errors.Email?.message && <p className="text-danger">{errors.Email?.message}</p>}
                    </div>
                    <div className="mb-2">
                        <button type='submit' className="btn btn-primary">{getLanguageContent("continue")}</button>
                    </div>
                </form>
                <div>{getLanguageContent("remember")} <Link to="/security/login">{getLanguageContent("clickhere")}</Link> {getLanguageContent("tosignin")}</div>
            </>
            }
        </>
    );
}

export default ForgotPassword;