import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './theme/default/custom.css';
import './custom.css';
import { ProgressContextProvider } from './services/utils/progress';
import { NotifyContextProvider } from './services/utils/notify';
import { ProfileProvider } from './services/utils/profileContext';
import { AccountSettingsProvider } from './services/Context/AccSettingContext';
import { ResourceProvider } from './services/Context/ManageResourceContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter basename={baseUrl}>
        <ProgressContextProvider>
            <NotifyContextProvider>
                <ResourceProvider>
                    <ProfileProvider>
                        <AccountSettingsProvider>
                            <App />
                        </AccountSettingsProvider>
                    </ProfileProvider>
                </ResourceProvider>
            </NotifyContextProvider>
        </ProgressContextProvider>
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
