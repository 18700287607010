import React, { useContext, useRef, useState, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';

import ProgressContext from '../../../services/utils/progress';
import { ecnSession, getAuthProfile, getSearchParams } from '../../../services/utils/validation';
import { useProfile } from '../../../services/utils/profileContext';
import NotifyContext from '../../../services/utils/notify';
import { apiConst, appConst, resourceConst } from '../../../services/constant/constant';
import { serverGet } from '../../../middleware/http';

export default function ClientLogout() {
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const post_logout_redirect_uri = getSearchParams("post_logout_redirect_uri");

    useEffect(() => {
        progParams(true);
        serverGet(apiConst.authlogout).then((res) => {
            try {
                if (res?.messageCode && res?.status == 1) {
                    localStorage.removeItem('langResource');
                    localStorage.removeItem('memberId');
                    localStorage.removeItem('sso_redirect_uri');
                    localStorage.removeItem("ExtOrderDetail");
                    ecnSession("clear");
                    getAuthProfile.clearProfileAuth();
                    sessionStorage.removeItem('redirectUrl');
                    // setTimeout(() => {
                    return window.location.href = post_logout_redirect_uri
                        //progParams(false);
                   // }, 1500)
                } else {
                    notifyToast(200);
                    progParams(false);
                }
            } catch (e) {
                console.log(e)
            }
        })
    });

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-3">
                        <div className="success-card">
                            <div className="card-body">
                                <h3 className="text-center"><i className="fa fa-spinner fa-spin"></i>Please wait...</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}