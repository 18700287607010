import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SvgIcon from '../icon/iconSvg';
import NavMenu from './NavMenu';
import Footer from './Footer';
import ProgressContext from '../../services/utils/progress';
import { dateFormat, ecnSession, getAuthProfile, isArray, validateImageSize } from '../../services/utils/validation';
import { useProfile } from '../../services/utils/profileContext';
import ImageCropContext from '../../services/utils/imageCrop';
import NotifyContext from '../../services/utils/notify';
import { useAccountSettings } from '../../services/Context/AccSettingContext';
import { apiConst, appConst, resourceConst } from '../../services/constant/constant';
import { serverGet } from '../../middleware/http';
import { useManageResource } from '../../services/Context/ManageResourceContext';
import { useLanguageRes } from '../../services/Context/LanguageContext';


const Layout = (props) => {
    //const loactionPaths = ["/dashboard", "/visit/detail", "/profile", "/changepassword", "/profile/idinfo","/vitals", "/insurance", "/module/transaction", "/module/forms", "/message", "/insuranceedit","/insuranceadd" , "/vitals", "/insurance",];
    const loactionPaths = ["/dashboard", "/visit/detail", "/profile", "/alldocuments", "/vitals", "/insurance", "/forms", "/message", "/familymember", "/consultation"];
    const location = useLocation();
    const navigate = useNavigate();
    const [profileResAction, setProfileResAction] = useState([]);
    const [getUserProfile, setUserProfile] = useState({ photoPath: null, dob: null, genderId: "" });
    const [file, setFile] = useState(null);
    const [photoURL, setPhotoURL] = useState('');
    const [homepageRes, setHomepageRes] = useState(null);
    //const [getSettingOption, setSettingOption] = useState([]);
    const [cropedURL, setCropedURL] = useState('');
    const [openCrop, setOpenCrop] = useState(false);
    const [setting, setSetting] = useState(false);
    const [resources, setResources] = useState(null);
    const { getLanguageContent } = useLanguageRes();

    const { notifyToast } = useContext(NotifyContext);
    const { progParams } = useContext(ProgressContext);
    const { profileContext, imageUrl, deleteProfilePhoto, patientInfo } = useProfile();
    const { showCropImage } = useContext(ImageCropContext);
    const { accountDate, updateHomeUrl } = useAccountSettings();
    const { getCurrentResource, resourceList, getCurrentResourceAction, actionExists } = useManageResource();
    const profileupload = useRef(null);
    const aspect = 1;
    //const accountSettingCode = 'CPI';

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);



    // console.log(patientInfo)

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target) && e.target !== buttonRef.current)
            setIsNavCollapsed(true);
    };

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    getUserProfile.photoPath = cropedURL == "" ? getUserProfile.photoPath : cropedURL;


    function CheckPath(pathnameToCheck) {
        if (location.pathname === pathnameToCheck) {
            return "active";
        }
        return "";
    }

    const logout = () => {
        progParams(true);
        let sso_detail = sessionStorage.getItem("sso_redirect_uri");
        if (sso_detail != "" && typeof sso_detail !== "undefined") {
            let sso_obj = JSON.parse(sso_detail);
            if (sso_obj?.client_id && sso_obj?.client_id !== "") {
                serverGet(apiConst.ssoSLOUrl).then((res) => {
                    try {
                        if (res?.data?.status) {
                            if (res?.data?.data) {
                                let redUri = res?.data?.data.replace("[", "").replace("]", "").replace(/['"]+/g, '');
                                sessionStorage.removeItem("sso_redirect_uri")
                                localStorage.removeItem('langResource');
                                localStorage.removeItem('memberId');
                                ecnSession("clear");
                                sessionStorage.removeItem('redirectUrl');
                                getAuthProfile.clearProfileAuth();
                                window.location.href = redUri;
                                return;
                            }
                        } else
                            AuthLogout();
                    } catch (e) {
                        console.log(e);
                        AuthLogout();
                    }
                })
            } else {
                AuthLogout();
            }
        }
    }

    const AuthLogout = () => {
        serverGet(apiConst.authlogout).then((res) => {
            try {
                if (res?.messageCode && res?.status == 1) {
                    localStorage.removeItem('langResource');
                    localStorage.removeItem('memberId');
                    ecnSession("clear");
                    getAuthProfile.clearProfileAuth();
                    sessionStorage.removeItem('redirectUrl');
                    sessionStorage.setItem("isAuth", false)
                    setTimeout(() => {
                        window.location.href = "/security/login"

                        return navigate(`/security/login`, { replace: true });

                        progParams(false);
                    }, 1500)
                } else {
                    notifyToast(200);
                    progParams(false);
                }
            } catch (e) {
                console.log(e)
            }
        })
    }

    const handleChange = (e) => {
        var isValidImg = validateImageSize(e.target?.files[0], 5);
        if (isValidImg == "succeed") {
            const file = e.target?.files[0];
            var photoURL = URL.createObjectURL(file);
            showCropImage({ ...{ photoURL, setOpenCrop, setPhotoURL, setFile, setCropedURL, aspect } });
            if (file) {
                setFile(file);
                setCropedURL(null)
                setPhotoURL(URL.createObjectURL(file));
                setOpenCrop(true);
            }
        } else {
            notifyToast(isValidImg);
        }

    };



    const handleDelete = () => {
        const confirmed = window.confirm('Are you sure you want to delete?');
        if (confirmed) {
            deleteProfilePhoto();
        }
    }


    useEffect(() => {

        if (isArray(resourceList?.resources)) {
            const _homePageResource = getCurrentResource(appConst.resource.publicResource);
            // HomePageRes
            if (Array.isArray(_homePageResource) && _homePageResource?.length > 0) {
                const _temp = _homePageResource?.find((list) => list.code == "CW6");
                if (_temp) {
                    setHomepageRes(_temp);
                    updateHomeUrl(_temp?.url);
                }
            }

            const fetchData = async () => {
                try {
                    const _resourceList = getCurrentResource(appConst.resource.menu);
                    setResources(_resourceList);
                    const actionList = () => {
                        const _actions = getCurrentResourceAction("PIN", "EQ7");
                        setProfileResAction(_actions);
                    };
                    actionList();

                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();

        }
    }, [resourceList?.resources])

    return (
        <>
            <SvgIcon />

            <>
                {!props.isViewer && <NavMenu cropedURL={cropedURL} setting={setting} isLogin={props.islogin} profile={profileContext} home={homepageRes} />}
                <div className={`bg-light ${loactionPaths?.includes(location.pathname) ? "" : "container-xxl p-3 p-md-4"} main-container`}>
                    {loactionPaths?.includes(location.pathname) ?
                        <div className="d-flex flex-md-row flex-column">
                            <div id='lnavbardiv' className={`l-navbar flex-shrink-0 p-3 align-self-stretch`}>
                                <div className="d-flex">
                                    <div className="me-3">
                                        <label htmlFor="flePhoto" className="border border-light-subtle rounded-3 position-relative profile-pic-upload py-4">
                                            <input ref={profileupload} onClick={(event) => {
                                                event.target.value = ''
                                            }} autoFocus={true} type="file" accept="image/png, image/jpeg, image/jpg" className="form-control" onChange={handleChange} />
                                            <img loading="lazy" className="rounded-2 img-fluid" width="100%" src={imageUrl ? imageUrl : profileContext?.resProfile?.photoPath ? "doc/getimage/true?url=" + encodeURIComponent(profileContext?.resProfile?.photoPath) : require('../../asset/images/blankuser.png')} alt="User Profile" />


                                            <div className="dropdown">
                                                <span type="button" data-bs-toggle="dropdown" aria-expanded="false" className="position-absolute  bottom-0 start-100 translate-middle badge border border-light rounded-circle bg-dark p-1 me-2 image-croper-camera-icon">
                                                    <svg width="16" height="16" role="img" fill="currentColor">
                                                        <use xlinkHref="#edit_icon_pencil"></use>
                                                    </svg>
                                                </span>
                                                <ul className="dropdown-menu">
                                                    <li onClick={() => {
                                                        if (profileupload.current) {
                                                            profileupload.current.click();
                                                        }
                                                    }}>
                                                        <a className="dropdown-item" >{getLanguageContent("dasupl")}</a>

                                                    </li>
                                                    {(profileContext?.resProfile?.photoPath && actionExists(profileResAction, resourceConst?.actions?.edit)) && (
                                                        <li onClick={handleDelete}>
                                                            <a className="dropdown-item">{getLanguageContent("dasrem")}</a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="flex-grow-1 position-relative">

                                        {(profileContext?.resProfile?.firstName && profileContext?.resProfile?.lastName) && (
                                            <h5>{`${profileContext?.resProfile?.firstName} ${profileContext?.resProfile?.lastName}`}</h5>
                                        )}
                                        <div className="d-flex align-items-center">
                                            {(profileContext?.resProfile?.dob) && (
                                                <>
                                                    <svg width="18" height="18" role="img"><use xlinkHref="#svg_cake" /></svg>
                                                    <span className="text-muted ms-2">{dateFormat(profileContext?.resProfile?.dob, accountDate?.value)}</span>
                                                </>
                                            )}
                                        </div>
                                        <div className="position-absolute end-0 d-block d-md-none" style={{ top: "25%" }}>
                                            <button class="btn btn-light" ref={buttonRef} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                                {isNavCollapsed ? <svg width="12" height="7"><use xlinkHref="#svg_arrowdown"></use> </svg>
                                                    : <svg width="7" height="12"><use xlinkHref="#svg_arrowright"></use> </svg>
                                                }
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div use={menuRef} className={`${isNavCollapsed ? 'collapse' : ''} nav_list navbar-collapse mt-4`} id="navbarSupportedContent">
                                    {(Array.isArray(resources) && resources.length > 0) && (
                                        <>
                                            {resources?.filter((list) => patientInfo?.parentId ? list?.code != "SK6" : true)?.map((menu, i) => (
                                                <>
                                                    {menu?.url?.includes("https://") ? (
                                                        <a className={`nav_link`}  href={menu?.url ? menu?.url : "/pagenotfound"}>
                                                            <svg className="nav_icon mt-1" width="24" height="24" role="img"><use xlinkHref={menu?.icon} /></svg>
                                                            <span className="nav_name">{menu?.title}</span>
                                                        </a>
                                                    ) : (
                                                        <Link onClick={() => setIsNavCollapsed(true)} className={`nav_link  ${CheckPath(menu?.url)}  ${location.pathname === '/visit/detail' && i == 0 ? "active " : ''} `} to={menu?.url ? `${menu?.url}?rcode=${menu?.code}` : "/pagenotfound"} key={i}>
                                                            <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref={menu?.icon} /></svg>
                                                            <span className="nav_name">{menu?.title}</span>
                                                        </Link>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                    <p onClick={logout} className="nav_link pointer-class">
                                        <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref="#svg_exit" /></svg>
                                        <span className="nav_name">{getLanguageContent("frmlgt")}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="flex-grow-1 m-3 content-container">
                                {props.children}
                            </div>
                        </div>
                        : props.children}


                </div>
                {!props.isViewer && <Footer />}
            </>

        </>
    );
}

export default Layout;
