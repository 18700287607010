import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Moment from 'moment';
import { serverGet, serverPost, serverPut } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import ProgressContext from "../../../services/utils/progress";
import { ecnSession, getCombinedList, getMemberId, getSearchParams, isArray, isEnabled, isFeautureEnabled } from "../../../services/utils/validation";
import DoctorSummary from "../consultation/summary/DoctorSummary";
import ScheduleHeader from "../consultation/summary/ScheduleHeader";
import NotifyContext from "../../../services/utils/notify";
import { getEncounterMode } from "../consultation/summary/ScheduleConfirmation";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import Subscription from "./Subscription";

export default function Payment() {
    const { register, handleSubmit, setValue, watch } = useForm();

    const navigate = useNavigate();
    const _watch = watch();

    const [selected, setSelected] = useState({ mode: "", type: "", plan: "", subscribed: "" });
    const [payMethods, setPayMethods] = useState([]);
    const [payType, setPayType] = useState([]);
    const [pricing, setPricing] = useState(null);
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const encounterModeId = getSearchParams("emid");

    const { getLanguageContent } = useLanguageRes();
    const { accountSettings, accountModules } = useAccountSettings();

    const onSubmit = async (data) => {
        try {

            progParams(true);
            if (!data?.mode) return notifyToast(342);

            //const pay_method = payMethods.find(item => item.settingOptionId == data?.mode)?.code
            let sessionStore = ecnSession();
            const pay_method = data?.mode;
            const schedule_id = getSearchParams("schid");
            const encounter_id = getSearchParams("eid");
            const durationId = getSearchParams("did");

            delete sessionStore?.planDetailId;
            delete sessionStore?.planId;
            delete sessionStore?.itemId;
            delete sessionStore?.memberPlanId;
            delete sessionStore?.enrollmentId;
            sessionStorage.setItem("sessionData", JSON.stringify(sessionStore));;

            ecnSession(schedule_id, "encid");
            ecnSession(encounter_id, "encounterId");


            const app_date = urlSearchParams.get('ad');
            const time_slot = urlSearchParams.get('ts');
            const duration_name = urlSearchParams.get('dn');
            const enc_mode = urlSearchParams.get('m');
            const enc_reason = urlSearchParams.get('r');
            const enc_id = urlSearchParams.get('eid');
            const enc_pharmacy = urlSearchParams.get('p');
            const minimum_price = getSearchParams("minP");
            const provider_memberId = getSearchParams("memberid");
            const fmid = getSearchParams("fmid");
            const _brtype = getSearchParams("brtype");

            let temp = ""
            const orderDetail = localStorage.getItem("orderDetail");
            if (orderDetail) {
                temp = JSON.parse(localStorage.getItem("orderDetail"));
                temp = temp?.order?.ExtOrderId
            }

            if (pay_method == "INS") {
                return navigate(`/payment/insurance?memberid=${provider_memberId}&ad=${app_date}&schid=${schedule_id}&minP=${minimum_price}&emid=${encounterModeId}&did=${durationId}&ts=${encodeURIComponent(time_slot)}&m=${enc_mode}&dn=${duration_name}&r=${enc_reason}&eid=${enc_id}&p=${encodeURIComponent(enc_pharmacy)}&spid=${getSearchParams("spid")}${fmid ? `&fmid=${fmid}` : ""}&brtype=${_brtype}`);
            } else if (pay_method == "IPPAY") {

                try {

                    const formData = {
                        paymethodType: 3,
                    }
                    if (temp) formData.orderId = temp;
                    return await updateSchedule(formData);

                } catch (e) {
                    console.log(e);
                }
            } else if (pay_method == "OPAY") {


                if ((payType.length > 1 && !selected.type) || (!selected?.plan && selected.type == "SME")) return notifyToast("319");

                if (selected?.mode == "OPAY" && selected.type == "SME" && selected?.plan?.planId) {
                    const _resCodes = ["374", "373"];
                    const res = await validatePlan(selected?.plan?.planId);
                    //return;
                    if (res?.data?.id) {

                        ecnSession(res?.data?.planDetailId, "planDetailId");
                        ecnSession(selected?.plan?.planId, "planId");
                        ecnSession(res?.data?.id, "itemId");

                        if (!selected?.subscribed) {

                            const save = await enrollmentSave(selected?.plan?.planId);

                            if (save?.status == 1 && save?.data) {
                                ecnSession(save?.data?.memberPlanId, "memberPlanId");
                                ecnSession(save?.data?.enrollmentId, "enrollmentId");

                                return navigate(`/paymentprocess?eid=${schedule_id}${fmid ? `&fmid=${fmid}` : ""}`)
                            } else {
                                console.log("else")
                                const error = JSON.parse(save?.data?.errorMessage);
                                return notifyToast(error?.messageCode[0]);
                            }
                        } else {
                            if (selected?.subscribed) {
                                try {
                                    const formData = {
                                        paymethodType: 1,
                                        skipPayment: "true",
                                        planDetailId: res?.data?.planDetailId,
                                        memberplanId: selected?.plan?.memberPlanId,
                                        itemId: res?.data?.id,
                                        planId: selected?.plan?.planId

                                    }
                                    if (temp) formData.orderId = temp;
                                    return await updateSchedule(formData);

                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                    } else {
                        if (_resCodes.includes(res?.data)) {
                            return notifyToast(res?.data);
                        } else {
                            return notifyToast("200");
                        }
                    }
                }

                if (selected.type != "SME")
                  navigate(`/paymentprocess?eid=${schedule_id}${fmid ? `&fmid=${fmid}` : ""}`);
            }
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);
        }
    }
    const updateSchedule = async (data) => {

        try {
            const scheduleId = getSearchParams("schid")
            const updateRes = await serverPut(`${apiConst.scheduleupdate}/${scheduleId}`, data);

            if (updateRes?.status == 1) {
                sessionStorage.removeItem('redirectUrl');
                return navigate(`/processPayment?eid=${scheduleId}`);
            } else {
                const error = JSON.parse(updateRes?.data?.errorMessage);
                return notifyToast(error?.messageCode[0]);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const validatePlan = async (id) => {
        try {
            const _specalityId = getSearchParams("spid");
            const _type = getSearchParams("ftypeid") || "1";

            const _memberId = await getMemberId();

            const res = await serverGet(`${apiConst.planValidate}${_memberId}?type=${_type}&specalityId=${_specalityId}&planId=${id}`)

            return res;
        } catch (e) {
            console.error(e);

        }
    }


    const enrollmentSave = async (id) => {
        try {
            const _memberId = await getMemberId();
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            let startOfDayUtc = Moment.utc(startOfDay).format("YYYY-MM-DDTHH:mm");
            const formData = {
                memberid: _memberId,
                PlanId: id,
                statusId: 3,
                enrolledOn: startOfDayUtc
            }


            const res = serverPost(apiConst.enrollmentSave, formData);

            return res;

        } catch (e) {
            console.error(e);
        }
    }


    const getAccountSettings = async () => {
        try {
            progParams(true);
            const encounterModeId = getSearchParams("emid");
            let minimum_price = getSearchParams("minP");
            minimum_price = (parseFloat(minimum_price) > 0) ? true : false;
            setPricing(minimum_price);
            const settingsCode = "PMTD,PFS,PAT";
            const getsetting = await serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(settingsCode)}`);
            if (((Array.isArray(getsetting?.data) && isEnabled(getsetting?.data, "PFS", 0)) || (!minimum_price))) {

                let temp = ""
                const orderDetail = localStorage.getItem("orderDetail");
                if (orderDetail) {
                    temp = JSON.parse(localStorage.getItem("orderDetail"));
                    temp = temp?.order?.ExtOrderId
                }
                const formData = {
                    paymethodType: 1,
                    skipPayment: "true",
                }
                if (temp) formData.orderId = temp;
                updateSchedule(formData);
            }
            const getvalue = await serverGet(apiConst.getsettingoptions + "/" + settingsCode);
            if (Array.isArray(getsetting?.data) && Array.isArray(getvalue?.data) && isEnabled(getsetting?.data, "PFS", 1)) {
                if (getsetting?.data?.length > 0 && getvalue?.data?.length > 0) {

                    const _payMethod = getCombinedList(getsetting?.data, getvalue?.data, "PMTD");
                    const _payType = getCombinedList(getsetting?.data, getvalue?.data, "PAT");

                    if (isArray(_payType)) setPayType(_payType);

                    if (isArray(_payMethod)) {
                        const list_result = _payMethod?.filter((list) => {
                            if (getEncounterMode(encounterModeId) == "In Person") {
                                return true;
                            } else {
                                return list.code !== "IPPAY"
                            }
                        });
                        setPayMethods(list_result);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            progParams(false);
        }
    };

    //console.log(selected);
    //console.log(getEncounterMode(encounterModeId))


    useEffect(() => {
        document.title = "Payment";
        const fetchData = async () => {
            try {
                await getAccountSettings();

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        if (payMethods?.length == 1) {
            setValue("mode", payMethods[0]?.code);
            handleChange(payMethods[0]?.code, "mode");
            //console.log(payMethods);
        }


    }, [payMethods]);


    useEffect(() => {

        if (payType?.length == 1) {
            handleChange(payType[0]?.code, "type");
            setValue("type", payType[0]?.code);
        }
    }, [payType]);



    const handleChange = (code, value) => {
        setSelected((prevState) => ({
            ...prevState,
            [value]: code,
        }));
    }


    //console.log(selected,"selected");

    return (
        <>

            {(isEnabled(accountSettings, "PFS", 1) && pricing) && (
                <>
                    <ScheduleHeader title={getLanguageContent("pymnt")} />
                    <div className="d-flex flex-md-row flex-column">
                        <DoctorSummary />
                        <div className="flex-grow-1">
                            <div className="card border-0">
                                <div className="card-body p-2 p-md-4">
                                    <h5>{getLanguageContent("chsprfrdmthd")}</h5>
                                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                        <div className="mt-2">
                                            {(payMethods?.length > 0 && isEnabled(accountSettings, "PFS", 1)) && payMethods?.map((list, i) => (
                                                <>
                                                    <div className="card mb-4">
                                                        <div className="card-body p-2 p-md-3 ">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div className="form-check" key={i}>
                                                                    <input className="form-check-input" type="radio" value={list?.code} id={list?.settingOptionId} onClick={(e) => handleChange(list?.code, "mode")} {...register("mode")} />
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <label className="form-check-label d-block fw-semibold" htmlFor={list?.settingOptionId}>
                                                                        {list?.displayText}
                                                                        {list?.description ? <>
                                                                            <br />
                                                                            <span className="text-secondary fw-normal">{list?.description}</span>
                                                                        </> : ""}
                                                                    </label>
                                                                </div>
                                                            </div>



                                                            {(isFeautureEnabled(accountModules, "SME") && list?.code == "OPAY" && isArray(payType) && payType?.length > 1 && selected?.mode == "OPAY" && payType?.find((l) => l?.code == "SME") && list?.code == "OPAY") && (
                                                                <div className="mt-2">
                                                                    <div className="ps-4">
                                                                        {(payType?.length > 0) && payType?.map((item, index, arr) => (

                                                                            <div onClick={(e) => handleChange(item?.code, "type")} key={`duration ${index}`} className={`${selected?.type == item?.code ? 'theme-border rounded' : ''}  w-100 mb-3 p-2`}>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="form-check" key={i}>
                                                                                        <input className="form-check-input" checked={item.code == selected?.type ? true : false} id={item?.code} type="radio" onClick={(e) => handleChange(item?.code, "type")} {...register("type")} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="form-check-label d-block fw-semibold" htmlFor={item?.code}>
                                                                                            <span>{item?.displayText}</span>
                                                                                            {item?.description ? <>
                                                                                                <br />
                                                                                                <small className="text-secondary fw-normal">{item?.description}</small>
                                                                                            </> : ""}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                {item.code == "SME" && list?.code == "OPAY" && selected?.mode == "OPAY" && selected?.type == "SME" && <Subscription handleChange={handleChange} selected={selected} />}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {isFeautureEnabled(accountModules, "SME") && payType?.length == 1 && list?.code == "OPAY" && selected?.mode == "OPAY" && selected?.type == "SME" && <Subscription handleChange={handleChange} selected={selected} />}

                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                        {isEnabled(accountSettings, "PFS", 1) && (
                                            <>
                                                <hr className="my-4 d-none d-sm-block" />
                                                <div className="my-4 text-end">
                                                    <button type="submit" className="btn btn-lg btn-primary">{getLanguageContent("prced")}</button>
                                                </div>
                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div >
                </>)
            }
        </>
    );
}





