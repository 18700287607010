import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { serverGet } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import { dateTimeFormat, isArray, isEnabled, timeFormat } from "../../../services/utils/validation";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import ProgressContext from "../../../services/utils/progress";
import NotifyContext from "../../../services/utils/notify";


export default function PendingAppointment({ showBtn,view }) {
    const [list, setList] = useState(null);
    const [showDiscard, setShowDiscard] = useState(false);
    const navigate = useNavigate();
    const { getLanguageContent } = useLanguageRes();
    const { accountDate, getAccountsettingValue, accountSettings } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);


    const handleDiscardApointment = (id) => {
        const result = window.confirm(getLanguageContent("dsbabd"))

        if (result) {
            discardAppointment(id);
        }

    }


    const discardAppointment = (id) => {
        progParams(true);

        serverGet(`${apiConst.discard}${id}`).then((res) => {
            progParams(false);

            if (res?.status == 1 && res?.data) {
                notifyToast(res.messageCode[0]);
                getPendingAppointment();
            } else {
                var error = JSON.parse(res?.data?.errorMessage);
                notifyToast(error?.messageCode[0]);
            }

        }).catch((e) => {
            console.log(e)
        })
    }


    const getPendingAppointment = () => {
        progParams(true);
        serverGet(`${apiConst.pendingApointment}`).then((res) => {
            progParams(false);

            if (res?.status == 1) {
                setShowDiscard(false);
                setList(res?.data)
            } else {
                var error = JSON.parse(res?.data?.errorMessage);
                notifyToast(error?.messageCode[0]);
            }

        }).catch((e) => {
            console.log(e)
        })
    }


    const recentOnClick = (item) => {
        const appointmentPriorDuration = getAccountsettingValue(accountSettings, "ADN");

        if (isEnabled(accountSettings, "SNP", 1) && parseInt(appointmentPriorDuration) > 0) {
            if (isTimeLessThan(item?.encounterOn, appointmentPriorDuration)) {
                return navigate(`/visit/detail?eid=${item?.id}&methodid=${item?.methodId}`);
            }
        } else {
            if (isTimeLessThan(item?.encounterOn)) {
                return navigate(`/visit/detail?eid=${item?.id}&methodid=${item?.methodId}`);
            }
        }

        setShowDiscard(true);

    }

    const isTimeLessThan = (dateTime, hours) => {
        const convertDatetime = (datetime) => {
            return new Date(datetime + 'Z');
        };
        const givenDateTime = convertDatetime(dateTime);
        const currentTime = new Date();

        if (hours) {
            const thresholdTime = new Date(currentTime.getTime() - hours * 60 * 60 * 1000);
            return thresholdTime < givenDateTime;
        } else {
            return currentTime < givenDateTime;
        }

    }

    useEffect(() => {
        getPendingAppointment();
    }, [])


    return (
        <>

            {(view && isArray(list)) && (
                <>
                    {list?.map((item) => (
                        <div class="alert alert-warning mb-3" role="alert">
                            <h5 className="mb-2">{getLanguageContent("prcdcmp")}</h5>
                            <p className="mb-2">{item?.encounterNo} | <span>{dateTimeFormat(item?.scheduleOn, accountDate?.value)} {timeFormat(item?.scheduleOn)}</span></p>
                            <p className="d-block mb-2">{getLanguageContent("pndappt")}</p>

                            {showDiscard && (
                                <div class="alert alert-danger" role="alert">{getLanguageContent("apprre")}</div>
                            )}
                            {showBtn && (
                                <button className="btn btn-lg btn-secondary me-2" onClick={() => handleDiscardApointment(item?.id)}>{getLanguageContent("dsbcrd")}</button>
                            )}
                            {!showDiscard && (
                                <button className="btn btn-primary btn-lg" onClick={() => recentOnClick(item)}>{getLanguageContent("clkhre")}</button>
                            )}
                        </div>
                    ))}

                </>
            )}

        </>
    )
}