import React, { useEffect, useState, useContext } from 'react';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import ActionStatus from '../../../services/utils/actionStatus';
import ProgressContext from '../../../services/utils/progress';
import { dateFormat, getBadgeColor, getStatusBadgeColor } from '../../../services/utils/validation';
import { useLanguageRes } from '../../../services/Context/LanguageContext';

export default function InsuranceList(props) {

    const [insuranceList, setInsuranceList] = useState([]);
    const { progParams } = useContext(ProgressContext);
    const { accountDate } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();

    const getList = () => {
        progParams(true)
        serverGet(apiConst.getinsurance).then((res) => {
            progParams(false)
            try {
                if (res?.data && res?.status == 1) {
                    props?.setInsuranceList(res?.data);
                    setInsuranceList(res?.data);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    const addInsurance = (id) => {
        props?.setInsuranceId(id);
        props?.toggleIns();
    }

    useEffect(() => {
        getList();
        props?.setInsuranceId(null);

    }, []);

    return (
        <>

            {(insuranceList?.length > 0 && insuranceList.filter((list) => props?.insuranceFlow ? list?.status?.toLowerCase() == "active" && list?.verificationStatusId != "4" : true)?.length > 0) && (
                <div className="row d-none d-md-flex px-md-3 align-items-center m-0 text-secondary">
                    <div className="col-4 col-xl-5">
                        <h6>{getLanguageContent("insrncename")}</h6>
                    </div>
                    <div className="col-2">
                        <h6>{getLanguageContent("plcyno")}</h6>
                    </div>
                    <div className="col-3">
                        <h6>{getLanguageContent("subscribr")}</h6>
                    </div>
                    <div className="col-3 col-xl-2">
                        <h6>{getLanguageContent("stats")}</h6>
                    </div>
                </div>
            )}
            {insuranceList?.length > 0 && insuranceList.filter((list) => props?.insuranceFlow ? list?.status?.toLowerCase() == "active" && list?.verificationStatusId != "4" : true)?.map((list, index) => (
                <div key={index} className={`row p-2 p-md-3 border border-1 d-flex align-items-center rounded m-0 my-3 ${index % 2 === 0 ? "insurance-list" : ""}`}>
                    <div className="col-md-4 col-xl-5">
                        {props?.insuranceFlow ?
                            <div className="form-check">
                                <input onChange={(e) => {
                                    props?.setInsuranceId(list?.id);
                                }} className="form-check-input" type="radio" name="insurance" id={list?.id} />
                                <label onClick={() => addInsurance(list?.id)} className="form-check-label pointer-class" for={list?.id}>
                                    {list?.insuranceName}
                                </label>
                            </div>
                            : <>
                                <span className="textarea-wrap pointer-class" onClick={() => addInsurance(list?.id)}>{list?.insuranceName}</span>  <br />
                            </>
                        }
                        {list?.expiryDate && <span className="text-secondary">{getLanguageContent("exprydt")}: {dateFormat(list?.expiryDate, accountDate?.value)}</span>}
                    </div>
                    <div className="col-md-2">
                        <span>{list?.policyNo}</span>
                    </div>
                    <div className="col-md-3">
                        <span>{list?.insuredName ? list?.insuredName : "Self"}</span>
                    </div>
                    <div className="col-md-3 col-xl-2">
                        <span className={`fw-normal badge rounded-pill border text-${getStatusBadgeColor(list?.status)} border-${getStatusBadgeColor(list?.status)}`}>{list?.status}</span> <br />
                    </div>
                </div>
            ))}
            {(insuranceList?.length === 0 || insuranceList.filter((list) => props?.insuranceFlow ? list?.status == "Active" && list?.verificationStatusId != "4" : true)?.length == 0 || insuranceList == null) && <ActionStatus description={getLanguageContent("fmnrfo")} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />}
        </>
    )
}
