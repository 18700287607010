import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ecnSession, getAuthProfile, getSearchParams, isEnabled } from "../../../services/utils/validation";
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useProfile } from '../../../services/utils/profileContext';
import ProgressContext from '../../../services/utils/progress';
import { serverGet } from '../../../middleware/http';
import { apiConst, resourceConst } from '../../../services/constant/constant';
import { useManageResource } from '../../../services/Context/ManageResourceContext';
import NotifyContext from '../../../services/utils/notify';

export default function AppointmentMode() {

    const navigate = useNavigate();
    const { getAccountSetting } = useAccountSettings();
    const { patientInfo, checkUserProfile, getFamilyList } = useProfile();
    const { progParams } = useContext(ProgressContext);
    const { getCurrentResourceAction, actionExists } = useManageResource();
    const { notifyToast } = useContext(NotifyContext);



    useEffect(() => {
        const refId = getSearchParams("refId");

        if (refId)
            serverGet("externalorder/getsessdetail/" + refId).then(res => {

                if (res?.orderDetail) {
                    localStorage.setItem("orderDetail", res.orderDetail);
                }
            });

    }, []);

    useEffect(() => {
        const extId = getSearchParams("extId");

        if (extId)
            serverGet(`${apiConst.getextdetail}${`${extId}`}`).then(res => {

                if (res) {
                    localStorage.setItem("ExtOrderDetail", JSON.stringify(res));
                }
            });

    }, []);

    useEffect(() => {
        let appointmentCode = getSearchParams("code") ?? "SD3";
        const navigateSharedCalender = async (spid, type, residingId) => {

            const addProfileUrl = `/addprofile?spid=${spid}&brtype=${type}${residingId ? `&${residingId}` : ""}`;
            const _identificationUrl = `/idverification/upload?spid=${spid}&brtype=${type}${residingId ? `&${residingId}` : ""}`;
            const bookingUrl = `/booking?spid=${spid}&brtype=${type}${residingId ? `&${residingId}` : ""}`;
            const _familyMemberUrl = `/selectfamilymember?spid=${spid}&brtype=${type}${residingId ? `&${residingId}` : ""}`
            const redirectUrl = {
                path: bookingUrl,
                bookingUrl: bookingUrl,
                familyMemberUrl: _familyMemberUrl,
                profileUrl: addProfileUrl,
            }

            let auth_res = getAuthProfile.getProfileAuth();
            if (auth_res == null || auth_res == undefined) {
                const res = await serverGet(apiConst.getauthprofile);
                if (res?.data?.auth) getAuthProfile.saveProfileAuth();
                auth_res = res?.data?.auth;
            }

            if (!auth_res || auth_res == "false") {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                return navigate(`/security/login?c=y`)
            }

            const accountSettings = await getAccountSetting();
            const _isProfile = await checkUserProfile();
            const _isFamilyMember = await getFamilyList();



            if (!_isProfile?.pofileCompleted) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                navigate(addProfileUrl);
            } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
                sessionStorage.setItem("redirectUrl", JSON.stringify(redirectUrl));
                navigate(_identificationUrl);
            } else if (_isFamilyMember) {
                navigate(_familyMemberUrl);
            } else {
                navigate(bookingUrl);
            }
        }

        const getStarted = async () => {

            try {
                progParams(true);
                const accountSettings = await getAccountSetting();
                const _specalityId = getSearchParams("spid");
                const _specalityCode = getSearchParams("scode");
                const _typeId = getSearchParams("type");
                const _parentId = getSearchParams("parentid");
                const _residingStateId = getSearchParams("rsid") || patientInfo?.residingStateId || null;
                const _groupCode = getSearchParams("gcode");
                const _Externalid = getSearchParams("extId");



                const patientInfo = await serverGet(apiConst.patientinfo);

                if (patientInfo?.data && patientInfo?.status == 1) {
                    //GROUP/SUBGROUP
                    if (_Externalid && _groupCode) {
                        const checkgroupcode = await serverGet(`${apiConst.updategroup}${`?code=${_groupCode}`}`);
                        if (checkgroupcode != 100) {
                            return notifyToast(234, undefined, undefined, {
                                param_name: "Invalid group"
                            })
                        }
                    }
                    //RESIDING STATE
                    const residingStateRes = await serverGet(`${apiConst.getlicenseregion}${_residingStateId ? `?sid=${_residingStateId}` : ""}`);
                    if (getSearchParams("rsid") && (residingStateRes?.status != 1 || (residingStateRes?.status == 1 && !residingStateRes?.data?.states))) {
                        return notifyToast(234, undefined, undefined, {
                            param_name: "Residing state id"
                        })
                    }
                    //SPECALITY
                    let _specality = await serverGet(`${apiConst.getproviderspeciality}${_specalityCode ? `?code=${_specalityCode}` : ""}`);
                    if (_specalityCode && (_specality?.status != 1 || (_specality?.status == 1 && !_specality?.data))) {
                        return notifyToast(234, undefined, undefined, {
                            param_name: "specialty code"
                        })
                    }

                    //COSULTATION TYPEID
                    if (_typeId == 2 && !_parentId) {
                        return notifyToast(234, undefined, undefined, {
                            param_name: "Parent encounter id"
                        })
                    } else if (_parentId && _typeId != "2") {
                        return notifyToast(234, undefined, undefined, {
                            param_name: "Type id"
                        })
                    }

                    //Followup encounter Get
                    if (_typeId == 2 && _parentId) {
                        const res = await serverGet(`${apiConst.getencounter}${_parentId?.toLowerCase()}`);
                        if (res?.status != 1 || !res?.data) {
                            return notifyToast(234, undefined, undefined, {
                                param_name: "Encounter id"
                            })
                        }
                        return navigate(`/booking?memberid=${res?.data?.providerId}&spid=${res?.data?.specialityId}&m=${res?.data?.encounterModeCode}&dn=${res?.data?.duration}&parent=${res?.data?.id}&ftypeid=2&brtype=${appointmentCode}${_residingStateId ? `&rsid=${_residingStateId}` : ""}`);
                    }


                    //if (_specalityId) {
                    //    const temp = _specality?.data.filter((list) => list.code == _specalityId);
                    //    if (temp?.length == 1) _specality.data = temp;
                    //}

                    //isResidingState && 
                    const stateRes = residingStateRes?.status == 1 && residingStateRes?.data?.states?.length > 0;
                    const specialityList = _specality?.data;
                    //const isResidingState = isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1);
                    const residingId = _residingStateId ? `rsid=${_residingStateId}` : ""
                    const withoutProvider = appointmentCode == "RW8" || appointmentCode == "GT6";

                    const shareCalender = getCurrentResourceAction("RW8", "DSB")
                    const consultNowWithoutProvider = getCurrentResourceAction("GT6", "DSB")
                    //const refId = getSearchParams("refId");

                    if (appointmentCode) {
                        if (!residingId && stateRes) { // checks for is residing state & location based services is enabled
                            navigate(`/residingstate?id=${specialityList?.length == 1 ? specialityList[0]?.id : "y"}&brtype=${appointmentCode}`); //checks speciality length
                        } else if (isEnabled(accountSettings, "ENSP", 1)) {
                            if (specialityList?.length == 1) {

                                if (withoutProvider) {
                                    return navigateSharedCalender(specialityList[0]?.id, appointmentCode, residingId)
                                }

                                const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-`);
                                if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                                    return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}&minP=${ProviderRes?.data[0]?.minimumPrice}&brtype=${appointmentCode}&sinp=t${residingId ? `&${residingId}` : ""}`);
                                }
                                navigate(`/provider?spid=${specialityList[0]?.id}&brtype=${appointmentCode}${residingId ? `&${residingId}` : ""}`);
                            } else {
                                navigate(`/specialty?brtype=${appointmentCode}${residingId ? `&${residingId}` : ""}`);
                            }
                        } else {
                            if (specialityList?.length == 1) {
                                if ((withoutProvider && (actionExists(shareCalender, resourceConst?.actions?.create) || actionExists(consultNowWithoutProvider, resourceConst?.actions?.create)))) {
                                    return navigateSharedCalender(specialityList[0]?.id, appointmentCode, residingId)
                                }

                                const ProviderRes = await serverGet(`${apiConst.getproviderlist}?spid=${specialityList[0]?.id}&kwd=-`);
                                if (ProviderRes?.status == 1 && ProviderRes?.data && ProviderRes?.data?.length == 1) {
                                    return navigate(`/profiledetail?memberid=${ProviderRes?.data[0]?.memberId}&spid=${specialityList[0]?.id}&minP=${ProviderRes?.data[0]?.minimumPrice}&brtype=${appointmentCode}&sinp=t${residingId ? `&${residingId}` : ""}`);
                                }
                            }

                            navigate(`/provider?brtype=${appointmentCode}${residingId ? `&${residingId}` : ""}`);
                        }
                    } else {
                        navigate("/dashboard");
                    }
                    ecnSession("clear");
                }
            } catch (e) {
                console.error(e);
            } finally {
                progParams(false);
            }
        }
        getStarted();
    }, [])





    return (
        <>

        </>
    )
}