import React, { useState, useContext, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { serverGet, serverPut } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { dateTimeFormat, getHtmlContent, getMsg, getSearchParams } from "../../../services/utils/validation";

export default function MedicationConsent() {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [consentList, setConsentList] = useState(null);

    const { getLanguageContent } = useLanguageRes();
    const { accountDate } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const navigate = useNavigate();


    const getSetting = () => {

        const encounter_id = getSearchParams("eid");
        const prescription_id = getSearchParams("pid");
        serverGet(`${apiConst.prescriptionList}${encounter_id}?id=${prescription_id}&typeId=${"1"}&statusId=${"5"}`).then((res) => {
            try {
                if (res?.data != null && res?.status == 1) {
                    if (Array.isArray(res?.data) && res?.data?.length > 0) {
                        if (res?.data[0]?.statusId == 2)
                            return navigate(`/dashboard`);
                        const response = res?.data[0];
                        const _consent = response?.details?.filter((list) => list?.isConsentRequired); // IsConsentRequired
                        if (_consent?.length == 0)
                            return navigate(`/dashboard`);
                        setConsentList({ ...response, details: _consent });
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })
    }


    const onSubmit = async (data) => {

        try {
            progParams(true);
            const prescription_id = getSearchParams("pid");

            const arrData = [...consentList?.details];

            const formData = [];

            arrData?.forEach(item => {
                if (Object.keys(data).includes(item.id)) {
                    formData.push({
                        IsConsentApproved: data[item.id],
                        id: item.id
                    })
                }
            });

            //console.log(formData);
            const res = await serverPut(`${apiConst.aproveConsent}/${prescription_id}`, formData);
            if (res?.status == '1') {
                notifyToast(res?.messageCode[0]);
                return navigate(`/dashboard`);
            }
            else {
                var error = JSON.parse(res?.data?.errorMessage);
                notifyToast(error?.messageCode[0]);
            }
        } catch (e) {
            console.log(e);
        } finally {
            progParams(false);
        }

    }

    useEffect(() => {
        document.title = "Medication consent";
        getSetting();
    }, [])

    return (
        <>
            {(consentList?.details && Array.isArray(consentList?.details) && consentList?.details?.length > 0) && (
                <>
                    <h3 className="mb-4">{getLanguageContent("medrcfr")}</h3>
                    <div className="card border-0">
                        <div className="card-body">
                            <h6><span className="text-secondary">{getLanguageContent("prscdon")} - </span>{dateTimeFormat(consentList?.details[0]?.prescribedOn, accountDate?.value)} </h6>
                            <h6><span className="text-secondary">{getLanguageContent("precdnum")} - </span>{consentList?.rxNo}</h6>
                            <h6><span className="text-secondary">{getLanguageContent("prscdby")} - </span>{consentList?.details[0]?.prescriberName}</h6>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                {consentList?.details?.map((list, i) => (
                                    <div key={list?.id}>
                                        <span className="btn btn-primary rounded-0">{list?.drugName} {list?.dosage}</span>
                                        <p className="my-2 fw-medium">{getLanguageContent("hlthcre")?.replace("{provider_name}", list?.prescriberName)}</p>
                                        <div className="my-2" dangerouslySetInnerHTML={{ __html: getHtmlContent(list?.consentContent) }}>
                                        </div>
                                        <div className="mb-2">
                                            <input type="checkbox" className="form-check-input" id={list?.id} {...register(list?.id, { required: getMsg(301) })} />
                                            <label className="form-check-label body-secondary-color ms-2" htmlFor={list?.id}><span className="h6">{getLanguageContent("agrecnst")}</span></label>
                                            {errors[list?.id]?.message && <p className="text-danger fw-semibold">{errors[list?.id]?.message}</p>}
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary">{getLanguageContent("medsave")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}