import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { serverDelete, serverDownloadFile, serverGet } from "../../../middleware/http";
import { apiConst, appConst } from "../../../services/constant/constant";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import ActionStatus from "../../../services/utils/actionStatus";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { dateTimeFormat, getMemberId, timeFormat } from "../../../services/utils/validation";

export default function DocumentList(props) {

    const [documents, setDocuments] = useState([]);


    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const { accountDate } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();
    const urlSearchParams = new URLSearchParams(window.location.search);

    const getUrl = async (value) => {
        try {
            const enc_id = urlSearchParams.get('eid');
            const fmid = urlSearchParams.get('fmid');
            const _member_id = await getMemberId();

            switch (value) {
                case 'encounter':
                    return `${apiConst.doclist}${fmid ? fmid :_member_id}?recordid=${enc_id}`
                case 'profile':
                    return `${apiConst.doclist}${fmid ? fmid : _member_id}?recordid=${fmid ? fmid : _member_id}&gn=${appConst.cateogries.proofUpload}`
                default:
                    return `${apiConst.doclist}${fmid ? fmid : _member_id}`
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getDocuments = async () => {
        try {
            progParams(true);
            const _url = await getUrl(props?.location);
            const res = await serverGet(_url);

            if (res?.status == 1)
                setDocuments(res?.data);

        } catch (e) {
            console.log(e)
        } finally {
            progParams(false);

        }
    }


    const download_File = async (path, contentType) => {

        const fileDownloadUrl = `/doc/getimage/true?url=${encodeURIComponent(path)}`
        let docName = path.split('_');
        if (docName.length > 1) {
            docName = docName.slice(1).join('_');
        }
        await serverDownloadFile(fileDownloadUrl, docName);
    };


    const deleteDoc = (id, filepath) => {

        if (window.confirm('Are you sure you want to delete?')) {
            const member_id = localStorage.getItem("memberId");
            const fmid = urlSearchParams.get('fmid');

            progParams(true);
            serverDelete(`${apiConst.docdelete}/${fmid ? fmid :member_id}?fp=${filepath}&docid=${id}`, "").then((res) => {
                progParams(false);
                try {
                    if (res.status == 1) {
                        notifyToast(res.messageCode[0]);
                        getDocuments();
                    }
                } catch (error) {
                    console.log(error);
                }
            }).catch((e) => {
                console.log(e)
            })

        }
    }


    useEffect(() => {
        getDocuments();
    }, [])
    return (
        <>
            {(documents?.length > 0 && documents && Array.isArray(documents)) && (
                <div className="mb-5" id="dViewMedication">
                    <div className="row d-none d-md-flex text-secondary">
                        <div className="col-9">
                            <div className="row">
                                <div className="col-3">{getLanguageContent("nme")}</div>
                                <div className="col-3 ">{getLanguageContent("ctgy")}</div>
                                <div className="col-3 ">{getLanguageContent("upldby")}</div>
                                <div className="col-3 ">{getLanguageContent("upldon")}</div>
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-2"></div>
                    </div><hr />
                    {documents?.length > 0 && documents?.map((list, i) => (
                        <>
                            <div className="row align-items-center" key={i}>
                                <div className="col-9">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col">
                                                    <span>{list?.fileName}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 m-auto">
                                            <div>
                                                {list?.docType}
                                            </div>
                                        </div>
                                        <div className="col-md-3 m-auto">
                                            <div>
                                                {list?.modifiedBy}
                                            </div>
                                        </div>
                                        <div className="col-md-3 m-auto">
                                            <div>
                                                <span>{dateTimeFormat(list?.modifiedOn, accountDate?.value)} </span>
                                                <span>{timeFormat(list?.modifiedOn)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1">
                                    {list?.isShared &&
                                        <span title="Shared">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z" />
                                                <path fill-rule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                                            </svg>
                                        </span>
                                    }
                                </div>
                                {(props?.deleteAction || props?.downloadAction) && (
                                    <div className="col-2">
                                        <div className="btn-group">
                                            <button type="button" className="btn border-0" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                </svg>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end me-2">
                                                {props?.downloadAction && (
                                                    <li onClick={() => download_File(list?.filePath, list?.contentType)} className="dropdown-item">{getLanguageContent("dwnlod")}</li>

                                                )}
                                                {props?.viewAction && (
                                                    <a href={`/docviewer?id=${list?.id}`} target="_blank" rel="noopener noreferrer">
                                                        <li className="dropdown-item">{getLanguageContent("viewwe")}</li>
                                                    </a>
                                                )}        
                                                {(!list?.isShared && props?.deleteAction) &&
                                                    <li onClick={() => deleteDoc(list?.id, list?.filePath)} className="dropdown-item">{getLanguageContent("dlte")}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                {list.note &&
                                    <div className="col-12 mt-2">
                                        <div className="d-sm-flex align-items-baseline">
                                            <div><p className="text-nowrap">{getLanguageContent("dscpton")}</p> </div>
                                            <div className="ms-sm-2"><pre className="textarea-wrap">{list?.note}</pre></div>
                                        </div>
                                    </div>
                                }
                                {/*    <hr />*/}
                            </div>
                            <hr className="m-2" />
                        </>
                    ))}
                </div>
            )}
            {(documents?.length == 0 || !documents) && (
                <div className="my-3">
                    <ActionStatus description={getLanguageContent("fmnrfo")} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />

                </div>
            )}
        </>
    )
}