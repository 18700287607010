import React, { useState, useEffect, useContext } from 'react'
import Dashboard from "./Dashboard";
import Profile from "../patient/Profile";
import { Link, useLocation } from 'react-router-dom';
//import ChangePassword from '../security/ChangePassword';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import ProgressContext from '../../../services/utils/progress';
import { dateTimeFormat, ecnSession, getAuthProfile } from '../../../services/utils/validation';
import DetailPage from '../consultation/DetaiLPage';


const menu = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid" viewBox="0 0 16 16">
            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
        </svg>
    },

    {
        name: 'Profile',
        path: '/profile',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
        </svg>
    },
    {
        name: 'Change password',
        path: '/changepassword',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
        </svg>
    },
    //{
    //    name: 'Form',
    //    path: '/forms',
    //    icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
    //        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    //    </svg>
    //},
]

const DashBoardLayout = (props) => {
    const location = useLocation();
    const { progParams } = useContext(ProgressContext);
    const [profile, setprofile] = useState();
    const queryParams = new URLSearchParams(location.search);

    const m_id = queryParams.get('mid');
  
    function CheckPath(pathnameToCheck) {
        if (location.pathname === pathnameToCheck  ) {
            return "bg-dark text-white rounded";
        }
        return 'text-dark';
    }


    const logout = () => {
        progParams(true);
        serverGet(apiConst.authlogout).then((res) => {
            progParams(false);
            try {
                if (res?.messageCode) {
                    localStorage.removeItem('langResource');
                    ecnSession("clear");
                    getAuthProfile.clearProfileAuth();
                    sessionStorage.removeItem('redirectUrl');
                    window.location.href = "/security/login"
                }
            } catch (e) {
                console.log(e)
            }
        })
    }

    return (
        <>
            <div className=" row">
                <div className="col-md-4 col-lg-3 d-none">
                    <div className="bg-white rounded-end text-dark py-4">
                        <div className="px-4 py-4 d-flex">
                            <div className='me-2'>
                                <img className="rounded-2 img-fluid table-avatar-img" src={!profile?.resProfile?.photoPath ? require('../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(profile?.resProfile?.photoPath)} alt="User Profile" />
                            </div>
                            <div>
                                {(profile?.resProfile?.firstName && profile?.resProfile?.lastName) &&
                               <>
                                    <span className='fw-bold ms-1'>{`${profile?.resProfile?.firstName} ${profile?.resProfile?.lastName}`}</span> <br />
                                </>
                                }
                                {profile?.resProfile?.dob &&
                                    <span><span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-balloon" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 9.984C10.403 9.506 12 7.48 12 5a4 4 0 0 0-8 0c0 2.48 1.597 4.506 4 4.984ZM13 5c0 2.837-1.789 5.227-4.52 5.901l.244.487a.25.25 0 1 1-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 1 1-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3.177 3.177 0 0 1-.052-.45l-.04.08a.25.25 0 1 1-.447-.224l.244-.487C4.789 10.227 3 7.837 3 5a5 5 0 0 1 10 0Zm-6.938-.495a2.003 2.003 0 0 1 1.443-1.443C7.773 2.994 8 2.776 8 2.5c0-.276-.226-.504-.498-.459a3.003 3.003 0 0 0-2.46 2.461c-.046.272.182.498.458.498s.494-.227.562-.495Z" />
                                    </svg></span>{dateTimeFormat(profile?.resProfile?.dob, "DD MMM YYYY")}</span>
                                }
                            </div>
                        </div>
                        <div className="px-4">
                            {menu?.map((list,i) => (
                                <div key={`child${i}`}>
                                    <Link className='text-decoration-none' to={list?.path}>
                                        <p className={`${CheckPath(list?.path)} ${location.pathname === '/visit/detail' && list?.path == '/dashboard' ? "bg-dark text-white rounded" :''  } m-0  p-3`}><span className='me-2'>{list?.icon}</span>{list?.name} </p>
                                    </Link>
                                </div>
                            ))}
                            <div>
                                <Link onClick={logout} to="#" className='text-decoration-none text-dark' > 
                                    <p className={` m-0  p-3`}>
                                        <span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                    </svg></span>
                                    Logout
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(() => {
                    switch (props.body) {
                        case 'profile': return (<Profile />)
                        //case 'changepassword': return (<ChangePassword />)
                        case 'detailpage': return (<DetailPage />)
                        /* case 'forms': return (<CustomForm />)*/
                        default: return (<Dashboard />)
                    }
                })()}
        </>

    );
}

export default DashBoardLayout;