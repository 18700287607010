import Moment from 'moment';
import DOMPurify from 'dompurify';
import he from "he";
import { serverGet } from "../../middleware/http";
import { apiConst } from "../constant/constant"

var errorMsg = [];
let formValidation = null;

const initFormValidation = async () => {
    const res = await serverGet(apiConst.getresponsecode + "/en-us");

    if (res?.data != null && res?.status == 1 && typeof res?.data !== "string" && Array.isArray(res?.data)) {
        localStorage.setItem("errorMsg", JSON.stringify(res.data))
    }
    
    formValidation = {
        email: {
            pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i, message: getMsg(204) }
        },
        password: {
            pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%?*.@#]).{8,}$/g, message: getMsg(314) }
        },
        invalidPassword: {
            pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%?*.@#]).{8,}$/g, message: getMsg(218) }
        },
        alphanumeric: {
            pattern: { value: /^[a-zA-Z0-9_]*$/, message: getMsg(313) }
        },
        alphabet: {
            pattern: { value: /^[a-zA-Z\s]*$/, message: getMsg(301) }
        },
        number: {
            pattern: { value: /^[0-9]+$/, message: getMsg(317) }
        },
        decimalnumbers: {
            pattern: { value: /^-?\d+(\.\d{1,2})?$/, message: getMsg(377) } //^-?\d+(\.\d+)?$ ^-?\d+(\.\d+)?$
        },
        time: {
            pattern: { value: /^(0[1-9]|1[0-2]):([0-5][0-9]) ((a|p)m|(A|P)M)$/, message: getMsg(321) }
        },
        phonenumber: {
            pattern: { value: /^\d+$/, message: getMsg(215) }
        },
        alphanumericspcl: {
            pattern: { value: /^[a-zA-Z0-9\s]+$/, message: getMsg(369) } //  /^[0-9]+$/ /^[0-9\s]+$/
        },
        feet: {
            pattern: { value: /^[1-8]$/, message: getMsg(371) }
        },
        inch: {
            pattern: { value: /^(?:0|[1-9]|10|11)$/, message: getMsg(371) }
        },
        validateNoEmptySpaces: (value) => {
            return value?.trim() === '' ? getMsg(370) : true;
        },
        filename_val: /^[^\/:*?"<>|#]*$/

    }
}

(async () => {
    await initFormValidation();
})();

export const getResponseCode =  () => {
    serverGet(apiConst.getresponsecode + "/en-us").then((res) => {
        try {
            if (res?.data != null && res?.status == 1 && typeof res?.data !== "string" && Array.isArray(res?.data)) {
                localStorage.setItem("errorMsg", JSON.stringify(res.data))
                return res?.data
            }
        } catch (error) {
            console.log(error);
        }
    })
}

export const getMemberId = async () => {
    try {
        let member_id = localStorage.getItem("memberId");
        if (!member_id) {
            const patient_res = await serverGet(apiConst.patientinfo);
            if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId) {
                member_id = patient_res?.data?.memberId;
                localStorage.setItem("memberId", member_id)
            }
        }
        return member_id;
    } catch (e) {
        console.error(e);
    }
}



export const getMsg = (code) => {
    let eCode = parseInt(code);

    const err_msg = localStorage.getItem("errorMsg")

    if (err_msg && Array.isArray(errorMsg)) {
        errorMsg = JSON.parse(localStorage.getItem("errorMsg"));
        if (!errorMsg || typeof errorMsg === "string") {
            serverGet(apiConst.getresponsecode + "/en-us").then((res) => {
                try {
                    if (res?.data != null && res?.status == 1 && typeof res?.data !== "string" && Array.isArray(res?.data)) {
                        localStorage.setItem("errorMsg", JSON.stringify(res.data))
                        return res?.data.find(em => em.code == eCode)?.description
                    }
                } catch (error) {
                    console.log(error);
                }
            })
        } else {
            const errMsg = errorMsg?.find(em => em?.code == eCode);
            if (errMsg && errMsg?.description) return errMsg?.description;
        }
    }
}


//export const formValidation = {
//    email: {
//        pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i, message: getMsg(204) }
//    },
//    password: {
//        pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%?*.@#]).{8,}$/g, message: getMsg(314) }
//    },
//    invalidPassword: {
//        pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%?*.@#]).{8,}$/g, message: getMsg(218) }
//    },
//    alphanumeric: {
//        pattern: { value: /^[a-zA-Z0-9_]*$/, message: getMsg(313) }
//    },
//    alphabet: {
//        pattern: { value: /^[a-zA-Z\s]*$/, message: getMsg(301) }
//    },
//    number: {
//        pattern: { value: /^[0-9]+$/, message: getMsg(317) }
//    },
//    decimalnumbers: {
//        pattern: { value: /^-?\d+(\.\d{1,2})?$/, message: getMsg(377) } //^-?\d+(\.\d+)?$ ^-?\d+(\.\d+)?$
//    },
//    time: {
//        pattern: { value: /^(0[1-9]|1[0-2]):([0-5][0-9]) ((a|p)m|(A|P)M)$/, message: getMsg(321) }
//    },
//    phonenumber: {
//        pattern: { value: /^\d+$/, message: getMsg(215) }
//    },
//    alphanumericspcl: {
//        pattern: { value: /^[a-zA-Z0-9\s]+$/, message: getMsg(369) } //  /^[0-9]+$/ /^[0-9\s]+$/
//    },
//    feet: {
//        pattern: { value: /^[1-8]$/, message: getMsg(371) }
//    },
//    inch: {
//        pattern: { value: /^(?:0|[1-9]|10|11)$/, message: getMsg(371) }
//    },
//    validateNoEmptySpaces: (value) => {
//        return value?.trim() === '' ? getMsg(370) : true;
//    },
//    filename_val: /^[^\/:*?"<>|#]*$/
//};

export const isBoolean = val => 'boolean' === typeof val;

export const checkIfValidUUID = (str) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
}

const Allowed = {
    Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
    Lowers: "qwertyuiopasdfghjklzxcvbnm",
    Numbers: "1234567890",
    Symbols: "!@#$%^&*"
}
export const customValidateCheckbox = (data) => {
    return data != "" ? true : getMsg(319);
}

const getRandomCharFromString = (str) => str.charAt(Math.floor(Math.random() * str.length))

export const generatePassword = () => {
    let pwd = "";
    const length = 8;
    pwd += getRandomCharFromString(Allowed.Uppers);  // pwd will have at least one upper
    pwd += getRandomCharFromString(Allowed.Lowers);  // pwd will have at least one lower
    pwd += getRandomCharFromString(Allowed.Numbers);  // pwd will have at least one number
    pwd += getRandomCharFromString(Allowed.Symbols); // pwd will have at least one symbol
    for (let i = pwd.length; i < length; i++)
        pwd += getRandomCharFromString(Object.values(Allowed).join(''));  // fill the rest of the pwd with random characters
    return pwd
}

export const redirectPageNotFound = () => {
    return window.location.href = "/pagenotfound"
}

export const customValidate = (watch, name, message, condition) => {
    let watchField = "";
    if (name == "") {
        watchField = watch();
        const data = watchField[condition.source];
        const filterData = data.filter(em => em[condition.compareFrom] === em[condition.compareTo]);
        return filterData.length > 0 ? true : message;
    }
    else {
        watchField = watch(name);
        return watchField.length > 0 ? true : message;
    }
}


export const updateHistory = (name, url) => {
    //const names = window.location.pathname;
    //const urls = `${window.location.pathname}${window.location.search}`

    let sessionObj = {};
    const storedSession = sessionStorage.getItem("sessionUrls");
    if (storedSession) {
        sessionObj = JSON.parse(storedSession);
    }

    if (name !== "clear" && url && name?.slice(1)) {
        sessionObj[name?.slice(1)] = url;
        sessionStorage.setItem("sessionUrls", JSON.stringify(sessionObj));
    } else if (name === "clear") {
        sessionStorage.removeItem("sessionUrls");
    }

    return sessionObj;
}


export const ecnSession = (value, name) => {
    let sessionObj = {};

    // Retrieve the session data from localStorage
    const storedSession = sessionStorage.getItem("sessionData");

    if (storedSession) {
        sessionObj = JSON.parse(storedSession);
    }

    if (value !== "clear" && value) {
        sessionObj[name] = value;
        sessionStorage.setItem("sessionData", JSON.stringify(sessionObj));
    } else if (value === "clear") {
        sessionStorage.removeItem("sessionData");
    }

    return sessionObj;
};

export const getAuthProfile = {
    saveProfileAuth: () => {
        sessionStorage.setItem("isAuth", true);
    },
    clearProfileAuth: () => {
        sessionStorage.removeItem("isAuth");
    },
    getProfileAuth: () => {
        const _temp = sessionStorage.getItem("isAuth");
        if (!_temp || _temp == "false") return false;

        return sessionStorage.getItem("isAuth");
    }
};


export const combineDateTime = (dateStr, timeStr) => {

    const formattedDate = Moment(dateStr, 'ddd MMM DD YYYY HH:mm:ss [GMT] Z (zz)').format('MM-DD-YYYY');
    const date = Moment(formattedDate, 'MM-DD-YYYY');
    const time = Moment(timeStr, 'hh:mm A');
    const combined_DateTime = date.clone().set({
        hour: time.hours(),
        minute: time.minutes(),
    });

    //const formattedDateTime = combinedDateTime.format('MM-DD-YYYY hh:mm A');


    const formattedDateTime = combined_DateTime.toDate()

    // console.log(formattedDateTime);

    return formattedDateTime;
}


//TO FORMAT UTC DATE & TIME


export const dateTimeFormat = (value, format) => {

    let date_format = format

    if (!date_format || date_format == '') {
        date_format = 'MM-DD-YYYY'
    }


    const convertDatetime = (datetime) => {
        const date = new Date(datetime + 'Z');
        return Moment(new Date(date)).format(`${date_format}`);
    };
    return convertDatetime(value);
}


export const dateAndTime = (value, format) => {

    let date_format = format

    if (!date_format || date_format == '') {
        date_format = 'MM-DD-YYYY'
    }

    const convertDatetime = (datetime) => {
        const date = new Date(datetime + 'Z');

        const momentDate = Moment(new Date(date));

        if (momentDate.isSame(Moment(), 'day')) {
            return momentDate.format("hh:mm A"); // Return time format
        } else {
            return momentDate.format(date_format); // Return date format
        }
    }
    return convertDatetime(value);

}




//TO FORMAT UTC TIME

export const timeFormat = (value, format) => {
    const dateFormat = format ? format : "hh:mm A"
    const time = value?.split('+')[0];
    const convertDatetime = (datetime) => {
        const date = new Date(datetime + 'Z');
        return Moment(new Date(date)).format(dateFormat);
    };

    return convertDatetime(time);
};

//TO FORMAT DATE & TIME

export const dateFormat = (value, format) => {

    let date_format = format

    if (!date_format || date_format == '') {
        date_format = 'MM-DD-YYYY'
    }

    return Moment(value).format(date_format)
}


export const getUtcTimeNow = () => {
    return Moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss");
}

//export const dateFormat = (value, format) => {

//    return Moment(value).format(format);
//}
export const getAge = (d1, d2) => {
    let Date1 = d1;
    let Date2 = d2;

    if (Date1) {
        Date1 = new Date(Moment(Date1, "YYYY-MM-DD"));
    }

    if (Date2) {
        Date2 = new Date(Moment(Date2, "YYYY-MM-DD"));
    } else {
        Date2 = new Date();
    }

    var diff = Date2.getTime() - Date1.getTime();
    return Math.floor(Math.abs(diff / (1000 * 60 * 60 * 24 * 365.25)));
}
//console.log(getAge(new Date(1978, 10, 3)));

export const getBadgeColor = (value) => {
    switch (value?.toLowerCase()) {
        case 'new':
            return 'info'
        case 'scheduled':
            return 'primary'
        case 'in progress':
            return 'secondary'
        case 'completed':
            return 'success'
        case 'active':
            return 'primary'
        default:
            return 'danger'
    }
}

export const getStatusBadgeColor = (value) => {
    switch (value?.toLowerCase()) {
        case 'active':
            return 'success'
        default:
            return 'danger'
    }
}

export const getScheduleColor = (value) => {
    switch (value) {
        case 0:
            return '';
        case 1:
            return 'theme-bg-blue';
        case 2:
            return 'theme-bg-yellow';
        case 3:
            return 'theme-bg-green';
        case 4:
            return 'theme-bg-red';
        case 5:
            return 'theme-bg-blue';
        case 6:
            return 'theme-bg-brown';
        case 7:
            return 'theme-bg-orange';
        case 8:
            return 'theme-bg-purple';
        default:
            return 'theme-bg-red'
    }
}



export const getEncounterColor = (value) => {
    switch (value) {
        case 0:
            return '';
        case 1:
            return 'theme-bg-blue';
        case 2:
            return 'theme-bg-yellow';
        case 3:
            return 'theme-bg-green';
        case 4:
            return 'theme-bg-red';
        case 5:
            return 'theme-bg-brown';
        default:
            return 'theme-bg-red'
    }
}


export const getStatusColor = (value) => {

    let _value;
    if (value)
        _value = value?.toString();
    switch (_value) {
        case "1":
            return 'primary';
        case "2":
            return 'danger';
        default:
            return "";
    }
}
export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const isEnabled = (arr, code, value) => {
    return arr?.some(item => item?.settingCode == code && item?.value == value);
};

export const isFeautureEnabled = (arr, code) => {
    return arr?.some(item => item?.featureCode == code && item?.id == item?.moduleFeatureId);
};


export const validateImageSize = (file, maxSizeInMB) => {
    const allowedSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (!file) {
        return 307;
    } else if (!file.type.startsWith("image/")) {
        return 307;
    } else if (file.size > allowedSizeInBytes) {
        return 306;
    } else {
        return "succeed";
    }
}

// TO UPDATE WINDOW SEARCH PARAMS
export const updateSearchParams = (name, value) => {
    if (value) {
        const newUrl = new URL(window.location);
        newUrl.searchParams.set(name, value);
        window.history.replaceState(null, '', newUrl);
    }
}


export const getSearchParams = (name) => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const value = urlSearchParams.get(name);

    if (value != "undefined" && value != "null" && value && value?.trim())
        return value?.trim();
}


export const removeSearchParam = (name) => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete(name);
    window.history.replaceState(null, '', newUrl);
};


export const getHtmlContent = (data) => {
    const cleanHtml = DOMPurify.sanitize(data);
    const decodedHTML = he.decode(cleanHtml);

    return decodedHTML;
}

export const isArray = (array) => {
    return Array.isArray(array) && array?.length > 0;
}

export const splitCountryCode = (number, accIsdcode, list) => {
    const string = String(number).trim();
    const parts = string.split(" ");

    if (parts.length >= 2) {
        const [isdCode, phoneNumber] = parts;

        const isValidIsdCode = list.some(item => item.isdCode === isdCode.trim());
        const code = isValidIsdCode ? isdCode.trim() : accIsdcode;
        const phonenum = isValidIsdCode ? phoneNumber.trim() : number;

        return { isdCode: code, phoneNumber: phonenum.trim() };
    }
    const phoneNumber = string.trim();
    return { isdCode: accIsdcode, phoneNumber };
};

export const replaceQuilliClassToBootstrap = (data) => {

    var newHTML = data.replace(/class="ql-align-center"/g, 'class="text-center mb-0"')
        .replace(/class="ql-align-justify"/g, 'class="justify-content mb-0"')
        .replace(/class="ql-size-large"/g, 'class="card-title"')
        .replace(/class="ql-active"/g, 'class="active"')
        .replace(/class="ql-disabled"/g, 'class="disabled"')
        .replace(/class="ql-bold"/g, 'class="fw-bold"')
        .replace(/class="ql-italic"/g, 'class="fst-italic"')
        .replace(/class="ql-align-right"/g, 'class="text-end mb-0"')
        .replace(/class="ql-underline"/g, 'class="text-decoration-underline"')
        .replace(/<p><br><\/p>/g, '<p class="mb-0"><br/></p>')
        .replace(/<pre class="ql-syntax" spellcheck="false">/g, '<htmlcode class="ql-syntax" spellcheck="false">')
        .replace(/<\/pre>/g, '</htmlcode>')
        .replace(/<p>/g, '<p class="mb-0">');
    return newHTML;
}
export const replaceBootstrapClassToQuilli = (data) => {
    var oldHTML = data.replace(/class="text-center"/g, 'class="ql-align-center"')
        .replace(/class="justify-content"/g, 'class="ql-align-justify"')
        .replace(/class="card-title"/g, 'class="ql-size-large"')
        .replace(/class="active"/g, 'class="ql-active"')
        .replace(/class="disabled"/g, 'class="ql-disabled"')
        .replace(/class="fw-bold"/g, 'class="ql-bold"')
        .replace(/class="fst-italic"/g, 'class="ql-italic"')
        .replace(/class="text-end"/g, 'class="ql-align-right"')
        .replace(/class="text-decoration-underline"/g, 'class="ql-underline"')
        .replace(/<htmlcode class="ql-syntax" spellcheck="false">/g, '<pre class="ql-syntax" spellcheck="false">')
        .replace(/<\/htmlcode>/g, '<\/pre>')
        .replace(/<p class="mb-0">/g, '<p>')
        .replace(/<p class="mb-0"><br\/><\/p> /g, '<p><br/></p>');

    return oldHTML;
}


export const getCombinedList = (settings, values, code) => {

    const filteredSettings = settings?.filter((list) => list.settingCode == code);
    const settingValue = filteredSettings[0]?.value?.split(",");
    const settingId = filteredSettings[0]?.settingOptionId;

    if (settingValue?.length > 0) {
        const result = values?.filter(
            (item) =>
                settingValue.includes(item?.settingOptionId) ||
                item?.settingOptionId == settingId
        );

        if (result?.length > 0) {
            return result;
        }
    }
    return [];

}

export { formValidation };
