import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import { serverGet } from "../../../middleware/http";
import { apiConst, appConst } from "../../../services/constant/constant";
import { useProfile } from "../../../services/utils/profileContext";
import { dateTimeFormat, getEncounterColor, timeFormat, isEnabled } from "../../../services/utils/validation";
import ProgressContext from "../../../services/utils/progress";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import { useDoctor } from '../../../services/Context/DoctorContext';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import ActionStatus from '../../../services/utils/actionStatus';

export default function Consultation() {
    const [list, setList] = useState([]);
    const [consultationList, setConsultationList] = useState(null);
    const { patientInfo } = useProfile();
    const { progParams } = useContext(ProgressContext);
    const { accountDate, cateogrySettings, accountSettings, isGWF } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();
    const navigate = useNavigate();
    const { providerprofile } = useDoctor();
    const toolTipRefs = useRef([]);

    const getlist = (id) => {
        try {
            progParams(true);
            serverGet(`${apiConst.encounterList}${id}`).then((res) => {                
                if (res?.status == 1) {
                    let _response = res?.data;
                    if (Array.isArray(_response) && _response?.length > 0) {
                        _response = _response?.filter((item) => item.statusId != 5);
                        _response?.forEach((parentItem) => {
                            const child_items = _response?.filter((childItem) => childItem?.parentId === parentItem?.id);
                            if (child_items?.length > 0) {
                                parentItem.child = child_items;
                                _response = _response.filter((item) => !child_items.includes(item));
                            }
                        });
                    }
                    setConsultationList(_response);
                    progParams(false);
                } else {
                    progParams(false);
                }
            }).catch((e) => {
                console.log(e)
            });


        } catch (e) {
            console.error(e)
        }
    }

    const encounterDetail = (encid, list) => {
        navigate(`/visit/detail?eid=${encid}&spid=${list?.specialityId}${list?.memberParentId && !patientInfo?.parentId ? `&fmid=${list?.memberId}` : ""}&methodid=${list?.methodId}`);
    }

    const providerUrl = async (id, typeId) => {
        try {
            if (typeId != 5) {
                progParams(true);
                const res = await providerprofile(id);
                if (res?.status == 1) {
                    const _response = res?.data;
                    const _specialities = _response?.specialities?.filter((list) => list?.id != null)
                    const _specialityId = (Array.isArray(_specialities) && _specialities?.length > 0) ? _specialities[0]?.specialityId : "";
                    return navigate(`/profiledetail?memberid=${_response?.member?.id}&spid=${_specialityId}&minP=${_response?.minimumPrice?.price}`);
                }
            }
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);

        }
    }

    const joinCallButton = (url) => {
        window.open(url, '_blank', 'noopener, noreferrer');
    }

    const followAppointment = async (list) => {
        try {
            progParams(true);
            const encounterResult = await serverGet(apiConst.getencounter + list?.id);
            return navigate(`/booking?${followUrl(encounterResult?.data, list)}`);
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);
        }
    }

    function followUrl(value, list) {
        const isResidingState = isEnabled(accountSettings, "URS", 1) && isEnabled(accountSettings, "SCL", 1);
        const residingId = isResidingState && patientInfo?.residingStateId ? `rsid=${patientInfo?.residingStateId}` : ""

        const providerId = value?.providerId;
        const spid = value?.specialityId; // parent specialty id
        const m = value?.encounterModeCode; // encounterMode
        const dn = value?.duration; // encounterDurationname

        return `memberid=${providerId}&spid=${spid}&m=${m}&dn=${dn}&parent=${value?.id}&ftypeid=2${residingId ? `&${residingId}` : ""}${list?.memberParentId && !patientInfo?.parentId ? `&fmid=${list?.memberId}` : ""}`
    }

    const getTitle = (id, value, settings) => {

        let freq_Value = JSON.parse(value);
        let template = getLanguageContent("recurtltip");
        const _tempWeekDays = []
        const _tempWeekOrdinal = []

        if (Array.isArray(freq_Value) && freq_Value?.length > 0) {
            freq_Value.forEach((item) => {
                if (item["WeekdayId"]) {
                    _tempWeekDays.push(item["WeekdayId"]);
                }
                if (item["WeekOrdinal"]) {
                    _tempWeekOrdinal.push(item["WeekOrdinal"])
                }
            })
        }

        const weekDays = settings?.filter(g => g.group === "weekdays_type" && _tempWeekDays.includes(g.id))?.map(l => l.name)?.join(", ");
        const weekOrdinal = settings?.filter(g => g.group === "weekordinal_type" && _tempWeekOrdinal.includes(g.id))?.map(l => l.name)[0] ?? "";


        if (id === 1) {
            return template.replace("{frequency}", getLanguageContent("recurday")).replace("{frequency_value}", `${weekOrdinal} ${weekDays}`);
        } else if (id === 2) {
            return template.replace("{frequency}", getLanguageContent("rcurweek")).replace("{frequency_value}", `${weekOrdinal} ${weekDays}`);
        } else if (id === 3) {
            return template.replace("{frequency}", getLanguageContent("recurmonth")).replace("{frequency_value}", `${weekOrdinal} ${weekDays}`);
        } else {
            return "";
        }

    }

    useEffect(() => {
        document.title = "Consultation";
        if (patientInfo?.id) {
            getlist(patientInfo?.id);
        }

    }, [patientInfo])

    useEffect(() => {
        if (Array.isArray(cateogrySettings) && Array.isArray(list)) {
                toolTipRefs.current = toolTipRefs.current.slice(0, list?.length);
                const itemTooltipInstances = toolTipRefs.current.map((ref) => {
                    if (ref) {
                        return new Tooltip(ref, {
                            trigger: 'hover focus click',
                            container: 'body',
                        });
                    }
                    return null;
                });

                return () => {
                    itemTooltipInstances.forEach((instance) => instance && instance.dispose());
                };
        }
    }, [list, cateogrySettings]);

    useEffect(() => {
        setList(consultationList);
    }, [consultationList]);
    return (
        <>
            <h4 className="mb-4">{getLanguageContent("dacnlt")}</h4>
            <div className="card border-0">
                <div className="card-body p-0 px-2 py-md-3 px-md-4 mb-2">
            {(Array.isArray(consultationList) && consultationList?.length > 0) && (
                <>
                            {consultationList?.map((item, i) => (
                         <>
                                <div className="border p-3 rounded-3 mt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="fw-bold m-0">{item?.patientName}</h6>
                                            <div>
                                                {((item?.encounterModeCode == appConst.encounterModes.online || item?.encounterModeCode == appConst.encounterModes.phoneOnline) &&
                                                    !isGWF &&
                                                    item?.joinUrl &&
                                                    accountSettings?.find(list => list?.settingCode == "VCP" && list?.value?.toLowerCase() != "none") &&
                                                    enableBtn(item?.scheduleOn, accountSettings, item?.scheduleStatusId)) ? (
                                                    <button type="button" className="btn btn-primary py-0 rounded-pill me-2 my-1" onClick={() => joinCallButton(item?.joinUrl)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                                                        </svg>
                                                        <span className="d-none d-lg-inline ms-1">{getLanguageContent("jncal")}</span>
                                                    </button>
                                                ) : ""}
                                                {(isEnabled(accountSettings, "FUA", 1) && item?.consultationTypeId == 1 && item?.scheduleStatusId == 3) && (
                                                   <button onClick={() => followAppointment(item)} type="button" className="d-none d-lg-inline btn btn-outline-primary py-0 rounded-pill ms-1 my-1 me-2">
                                                            {getLanguageContent("fllwup")}
                                                        </button>                                                     
                                                )}
                                                <button type="button" style={{ cursor: "text" }} className={`py-0 btn ${getEncounterColor(item?.statusId)} btn-sm`}>{item?.encounterStatus}</button>

                                                {(isEnabled(accountSettings, "FUA", 1) && item?.consultationTypeId == 1 && item?.scheduleStatusId == 3) && (
                                                    <div className="d-inline d-lg-none">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn border-0 p-0" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                                                <svg width="20" height="20" role="img">
                                                                    <use xlinkHref="#three_dots_menu"></use>
                                                                </svg>
                                                            </button>
                                                            <ul style={{ minWidth: "auto" }} className="dropdown-menu dropdown-menu-end me-2">
                                                                <li><a className="dropdown-item" onClick={() => followAppointment(item)} rel="noopener noreferrer">{getLanguageContent("fllwup")}</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                    </div>
                                    <div className="mb-2">
                                            <small><span className="text-primary fw-bold border-bottom border-2 me-1 pointer-class" onClick={() => encounterDetail(item?.id, item)}>#{item?.encounterNo}</span> | <span>{item?.reason}</span>
                                            {(item?.rescheduleRefId) && (
                                                <span className={`ms-2 badge rounded-pill border text-primary border-primary`}>{"Rescheduled"}</span>
                                            )}
                                            {(item?.occurrenceId) && (
                                                    <span className="mx-1"  ref={(el) => (toolTipRefs.current[i] = el)} data-bs-toggle="tooltip" key={item?.id} data-bs-placement="top" title={getTitle(item?.frequencyId, item?.frequencyValue, cateogrySettings)}>
                                                       <svg width="20" height="20" role="img"><use xlinkHref="#svg_recurrence"></use></svg>
                                                    </span>
                                             )}    
                                        </small>                                                                                  
                                    </div>
                                   
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-4 d-flex align-items-center mb-2 m-lg-0 ">
                                            <img className="flex-shrink-0 rounded-circle border me-2" width="50" height="50" src={item?.providerPhoto ? "doc/getimage/true?url=" + encodeURIComponent(item?.providerPhoto) : require('../../../asset/images/blankuser.png')} alt="User Profile" />
                                            <div>
                                                    <h6 className={`${item?.providerTypeId != 5 ? "text-primary pointer-class border-bottom border-2" : ""} fw-bold`} onClick={() => providerUrl(item?.providerId, item?.providerTypeId)} style={{ width:'fit-content' }}>{item?.providerName}</h6>
                                                <small>{item?.specialityName}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="text-secondary">
                                                            <small>{getLanguageContent("apptmton")}</small>
                                                        </div>
                                                        <div className="d-flex flex-wrap">
                                                            <span className="me-1">{dateTimeFormat(item?.encounterOn, accountDate?.value)} {timeFormat(item?.encounterOn)}</span>
                                                            <span>({item?.duration})</span>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-secondary">
                                                            <small>{getLanguageContent("clmode")}</small>
                                                    </div>
                                                    <div>
                                                        {item?.encounterMode}
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="text-secondary">
                                                            <small>{getLanguageContent("cltype")}</small>
                                                    </div>
                                                    <div>
                                                        {item?.consultationType}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                         </div>
                         
                                    {(item?.child && item?.child?.length > 0) && (
                                        <>
                                            {item?.child?.map((item, index) => (
                                                <div className="d-flex my-0" key={item?.id}>
                                                    <div className="d-flex align-items-center me-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5" />
                                                        </svg>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="border p-3 rounded-3 mt-3">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h6 className="fw-bold m-0">{item?.patientName}</h6>
                                                                <div>
                                                                    {((item?.encounterModeCode == appConst.encounterModes.online || item?.encounterModeCode == appConst.encounterModes.phoneOnline) &&
                                                                        !isGWF &&
                                                                        item?.joinUrl &&
                                                                        accountSettings?.find(list => list?.settingCode == "VCP" && list?.value?.toLowerCase() != "none") &&
                                                                        enableBtn(item?.scheduleOn, accountSettings, item?.scheduleStatusId)) ? (
                                                                        <button type="button" className="btn btn-primary py-0 rounded-pill me-2 my-1" onClick={() => joinCallButton(item?.joinUrl)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
                                                                            </svg>
                                                                            <span className="d-none d-lg-inline ms-1">{getLanguageContent("jncal")}</span>
                                                                        </button>
                                                                    ) : ""}
                                                                    {(isEnabled(accountSettings, "FUA", 1) && item?.consultationTypeId == 1 && item?.scheduleStatusId == 3) && (
                                                                        <button onClick={() => followAppointment(item)} type="button" className="d-none d-lg-inline btn btn-outline-primary py-0 rounded-pill ms-1 my-1 me-2">
                                                                            {getLanguageContent("fllwup")}
                                                                        </button>
                                                                    )}
                                                                    <button type="button" style={{ cursor: "text" }} className={`py-0 btn ${getEncounterColor(item?.statusId)} btn-sm`}>{item?.encounterStatus}</button>

                                                                    {(isEnabled(accountSettings, "FUA", 1) && item?.consultationTypeId == 1 && item?.scheduleStatusId == 3) && (
                                                                        <div className="d-inline d-lg-none">
                                                                            <div className="btn-group">
                                                                                <button type="button" className="btn border-0 p-0" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                                                                                    <svg width="20" height="20" role="img">
                                                                                        <use xlinkHref="#three_dots_menu"></use>
                                                                                    </svg>
                                                                                </button>
                                                                                <ul style={{ minWidth: "auto" }} className="dropdown-menu dropdown-menu-end me-2">
                                                                                    <li><a className="dropdown-item" onClick={() => followAppointment(item)} rel="noopener noreferrer">{getLanguageContent("fllwup")}</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="mb-2">
                                                                <small><span className="text-primary fw-bold border-bottom border-2 me-1 pointer-class" onClick={() => encounterDetail(item?.id, item)}>#{item?.encounterNo}</span> | <span>{item?.reason}</span>
                                                                    {(item?.rescheduleRefId) && (
                                                                        <span className={`ms-2 badge rounded-pill border text-primary border-primary`}>{"Rescheduled"}</span>
                                                                    )}
                                                                    {(item?.occurrenceId) && (
                                                                        <span className="mx-1" ref={(el) => (toolTipRefs.current[i] = el)} data-bs-toggle="tooltip" key={item?.id} data-bs-placement="top" title={getTitle(item?.frequencyId, item?.frequencyValue, cateogrySettings)}>
                                                                           <svg width="20" height="20" role="img"><use xlinkHref="#svg_recurrence"></use></svg>
                                                                        </span>
                                                                    )}
                                                                </small>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-5 col-xl-4 d-flex align-items-center mb-2 m-lg-0 ">
                                                                    <img className="flex-shrink-0 rounded-circle border me-2" width="50" height="50" src={item?.providerPhoto ? "doc/getimage/true?url=" + encodeURIComponent(item?.providerPhoto) : require('../../../asset/images/blankuser.png')} alt="User Profile" />
                                                                    <div>
                                                                        <h6 className={`${item?.providerTypeId != 5 ? "text-primary pointer-class border-bottom border-2" : ""} fw-bold`} onClick={() => providerUrl(item?.providerId, item?.providerTypeId)} style={{ width: 'fit-content' }}>{item?.providerName}</h6>
                                                                        <small>{item?.specialityName}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-xl-8">
                                                                    <div className="row">
                                                                        <div className="col-5">
                                                                            <div className="text-secondary">
                                                                                <small>{getLanguageContent("apptmton")}</small>
                                                                            </div>
                                                                            <div>
                                                                                <span>{dateTimeFormat(item?.encounterOn, accountDate?.value)} {timeFormat(item?.encounterOn)}</span>
                                                                                <span>({item?.duration})</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="text-secondary">
                                                                                <small>{getLanguageContent("clmode")}</small>
                                                                            </div>
                                                                            <div>
                                                                                {item?.encounterMode}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <div className="text-secondary">
                                                                                <small>{getLanguageContent("cltype")}</small>
                                                                            </div>
                                                                            <div>
                                                                                {item?.consultationType}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>  
                            ))}
                </>
            )}
                    {((consultationList != "loading") && (consultationList?.length === 0 || consultationList == null || consultationList?.filter((item) => item?.id != "00000000-0000-0000-0000-000000000000")?.length == 0)) && <ActionStatus description={getLanguageContent("fmnrfo")} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />}
                </div>
            </div>
        </>
    )
}

function enableBtn(date, enableJoin, status) {

    if (status != 1 && status != 5 && status != 2 && status != 3) {
        return false;
    }

    let ENJN = []
    let BECON = []
    let AFCON = []

    const convertDatetime = (datetime) => {
        return new Date(datetime + 'Z');
    };

    const givenDateTime = convertDatetime(date);
    const currentTime = new Date();

    const timeDifferenceInMillis = givenDateTime - currentTime;
    const minuteDifference = Math.floor(timeDifferenceInMillis / (1000 * 60));
    const dayDifference = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60 * 24));
    const absDayDifference = Math.abs(dayDifference);

    if (enableJoin?.length > 0) {
        ENJN = enableJoin?.filter((list) => list?.settingCode === 'ENJN')
        BECON = enableJoin?.filter((list) => list?.settingCode === 'BECON')
        AFCON = enableJoin?.filter((list) => list?.settingCode === 'AFCON')
    }



    if (ENJN[0]?.value == "1") {
        if (status == 3) {
            if (parseInt(AFCON[0].value) > 0 && absDayDifference >= 0 && absDayDifference <= AFCON[0].value) {
                return true;
            } else {
                return false;
            }
        } else if ((minuteDifference <= BECON[0].value && minuteDifference >= 0) || (currentTime.getTime() >= givenDateTime.getTime())) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }  
}