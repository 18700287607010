import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { serverGet, serverPut } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import NotifyContext from "../../../services/utils/notify";
import { ecnSession, getSearchParams } from "../../../services/utils/validation";
import { useLanguageRes } from '../../../services/Context/LanguageContext';

export default function ProcessPayment() {

    const navigate = useNavigate();
    const { notifyToast } = useContext(NotifyContext);
    const { getLanguageContent } = useLanguageRes();


    const getStatus = async () => {
        const sessionStore = ecnSession();
        const urlSearchParams = new URLSearchParams(window.location.search);
        const order_id = urlSearchParams.get('id');
        const schedule_id = urlSearchParams.get('eid');
        const paymentRefId = getSearchParams("subscriptionId");
        //const type_id = urlSearchParams.get('typeId');
        const click = urlSearchParams.get('cancelClick');
        try {
            if (schedule_id && schedule_id != "")
                return setTimeout(() => navigate(`/confirmation?eid=${schedule_id}`, { replace: true }), 1800);
            if (order_id && sessionStore?.encid) {
                setTimeout(async () => {
                    let temp = ""
                    const orderDetail = localStorage.getItem("orderDetail");
                    if (orderDetail) {

                        temp = JSON.parse(localStorage.getItem("orderDetail"));
                        temp = temp?.order?.ExtOrderId
                    }

                    const enrollid = sessionStore?.enrollmentId;
                    const memberplanid = sessionStore?.memberPlanId;
                    const plandetailid = sessionStore?.planDetailId;
                    const itemId = sessionStore?.itemId;
                    const planid = sessionStore?.planId;

                    const formData = {
                        paymethodType: 1,
                        transactionId: order_id
                    }
                    if (enrollid) formData.enrollId = enrollid;
                    if (memberplanid) formData.memberPlanId = memberplanid;
                    if (plandetailid) formData.planDetailId = plandetailid;
                    if (itemId) formData.itemId = itemId;
                    if (planid) formData.planId = planid;
                    if (temp) formData.orderId = temp;
                    if (paymentRefId) formData.paymentRefId = paymentRefId;

                    const res = await serverPut(`${apiConst.scheduleupdate}/${sessionStore?.encid}`, formData);
                    if (res?.data?.paymentStatus && res?.status == 1) {
                        sessionStorage.removeItem('redirectUrl');
                        return navigate(`/confirmation?eid=${res?.data?.scheduleId}`, { replace: true });
                    } else {
                        navigate(`/payment/fail?click=${click}`, { replace: true });
                        var error = JSON.parse(res?.data?.errorMessage);
                        if (error)
                            notifyToast(error?.messageCode[0]);
                    }
                }, 2000);
            } else {
                navigate(`/payment/fail?click=${click}`, { replace: true });
            }
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        document.title = "Processing";
        getStatus();
    }, []);


    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "78.5vh" }}>
                <div className="text-center my-auto">
                    <div className="spinner mx-auto"></div>
                    <h3>{getLanguageContent("pldrep")}</h3>
                </div>
            </div>
        </>
    )
}