import React, { useContext, useEffect, useState } from "react"
import { apiConst } from "../../../services/constant/constant"
import { getMemberId, isArray } from "../../../services/utils/validation"
import { serverGet } from "../../../middleware/http"
import ProgressContext from "../../../services/utils/progress";
import ActionStatus from "../../../services/utils/actionStatus";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";

export default function Subscription({ handleChange, selected }) {
    const [list, setList] = useState("");
    //const [subscribed, setSubscribred] = useState(false);
    const { progParams } = useContext(ProgressContext);
    const { AccountCurrency, showAccPricing } = useAccountSettings();


    //const [selected, setSelected] = useState("");

    const getList = async () => {
        try {
            setList("loading");
            progParams(true);
            const _memberId = await getMemberId();
            const res = await serverGet(`${apiConst.getPlanList}${_memberId}`)
            progParams(false);
            if (res.status == 1) {
                if (isArray(res?.data)) {
                    const _temp = res?.data?.find((l) => l.memberPlanId && l.memberId);
                    if (_temp) {
                        //console.log(_temp,"temp")
                        handleChange(_temp, "plan");
                        handleChange(true, "subscribed");

                        //setSubscribred(true);
                    } else {
                        handleChange("", "plan");
                    }
                }
                setList(res?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubscription = (list) => {
        if (!selected?.subscribed)
            handleChange(list, "plan");
    }

    useEffect(() => {

        getList();
    }, [])


    // console.log(selected);
    return (
        <>
            {isArray(list) ? (
                <>
                    <div className="row mt-2">
                        {list.map((item, i, arr) => (
                            <div className={`col-lg-${12 / arr.length} mb-2 d-flex align-items-stretch ${!selected?.subscribed ? `pointer-class` : ''} `}>
                                <div className="card w-100 position-relative" onClick={() => handleSubscription(item)}>
                                    <div className="card-body p-0">
                                        <div className="flex-grow-1">
                                            <div className="p-2">
                                                <label className="form-check-label d-block fw-semibold" htmlFor={item.planId} >

                                                <span className=" d-block">
                                                    {item?.name}
                                                </span>
                                                {item?.description ? <>

                                                    <small className="text-secondary fw-normal">{item?.description}</small>
                                                </> : ""}

                                                {item?.price ? <>

                                                        <span className="text-secondary d-block fw-normal">{AccountCurrency?.value}{parseFloat(item?.price)?.toFixed(2)}</span>
                                                    </> : ""}
                                                </label>
                                            </div>
                                            {/*    {item.planId == selected?.plan?.planId && <div className="bg-primary text-center text-white">{item?.memberPlanId ? "Subscribed and use this plan" : "Subscribe"}</div>}*/}
                                        </div>

                                        {(item?.memberPlanId ? item.planId == selected?.plan?.planId : !selected?.subscribed) && (
                                            <div className="position-absolute top-0 end-0 p-2">
                                                <input checked={item.planId == selected?.plan?.planId || item?.memberPlanId ? true : false} id={item.planId} className="form-check-input" type="radio" />

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) :
                (list != "loading" && (!list || list?.length <= 0)) ? (
                    <>
                        <ActionStatus description={`No records found.`} button={""} onsyncchange={""} descriptionClass={"fw-normal"} />

                    </>
                ) : ""
            }

        </>
    )
}